import { localStorageKeys } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import { updateCurrentAccount } from '@/redux/slices/account';
import { updateAuthData } from '@/redux/slices/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useReverseGeocoding from './useReverseGeocoding';
const useDeviceLocation = () => {
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
  const {
    fetchAddresses
  } = useReverseGeocoding();
  const dispatch = useDispatch();
  const updateLocationStatus = async (status, message, locationData = {}) => {
    let coordinates = {};
    if (locationData.data) {
      coordinates = {
        lat: locationData.data.latitude,
        lng: locationData.data.longitude
      };
    }
    const latlng = Object.values(coordinates).join(',');
    const addresses = (await fetchAddresses([latlng])) || [];
    const {
      address_components
    } = addresses[0] || {};
    const currentCountry = address_components?.find(i => i.types.includes('country'));
    const deviceLocation = {
      status,
      message,
      country: currentCountry,
      address: addresses?.[0]?.formatted_address,
      isLocationAllowed: locationData.isLocationAllowed,
      data: {
        ...coordinates
      }
    };
    actions.updateNestedValue(localStorageKeys.DEVICE_LOCATION, deviceLocation);
    dispatch(updateCurrentAccount({
      deviceLocation
    }));
    dispatch(updateAuthData({
      isDeviceLocationAllowed: status
    }));
  };
  const handleSuccess = position => {
    const {
      latitude,
      longitude
    } = position.coords;
    updateLocationStatus(true, 'Location retrieved successfully', {
      isLocationAllowed: true,
      data: {
        latitude,
        longitude
      }
    });
    return {
      status: true,
      message: 'Success',
      data: {
        latitude,
        longitude
      }
    };
  };
  const handleError = error => {
    const message = 'Location access denied by user';
    updateLocationStatus(false, message, {
      isLocationAllowed: false
    });
    console.error('Geolocation error:', {
      error
    });
    return {
      status: false,
      message
    };
  };
  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        const message = 'Geolocation is not supported by your browser';
        updateLocationStatus(false, message, {
          isLocationAllowed: false
        });
        reject(message);
      } else {
        navigator.geolocation.getCurrentPosition(position => resolve(handleSuccess(position)), error => reject(handleError(error)), {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        });
      }
    });
  };
  useEffect(() => {
    const checkPermission = async () => {
      if (!('permissions' in navigator)) {
        console.error('Permissions API is not supported in this browser.');
        return;
      }
      try {
        const permissionStatus = await navigator.permissions.query({
          name: 'geolocation'
        });
        const handlePermissionChange = () => {
          switch (permissionStatus.state) {
            case 'granted':
              getLocation().catch(console.error);
              break;
            case 'denied':
              updateLocationStatus(false, 'Location access denied', {
                isLocationAllowed: false
              });
              break;
          }
        };
        permissionStatus.addEventListener('change', handlePermissionChange);
        return () => {
          permissionStatus.removeEventListener('change', handlePermissionChange);
        };
      } catch (error) {
        console.error('Error checking geolocation permission:', error);
      }
    };
    checkPermission();
  }, []);
  return {
    getLocation
  };
};
export default useDeviceLocation;