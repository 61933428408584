import { useRouter } from 'next/router';
import { useEffect } from 'react';
const AppStoreOrPlayStoreRedirect = () => {
  const router = useRouter();
  const appStoreURL = 'https://apps.apple.com/us/app/id1451570624';
  const playStoreURL = 'https://play.google.com/store/apps/details?id=com.chickinguae';
  const isAndroid = () => /Android/i.test(navigator.userAgent);
  const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const redirect = () => {
    setTimeout(() => {
      if (isAndroid()) {
        window.location.href = playStoreURL;
      } else if (isIOS()) {
        window.location.href = appStoreURL;
      }
      redirectToHome();
    }, 10);
  };
  const redirectToHome = () => {
    setTimeout(() => {
      router.push('/');
    }, 1000); // Redirect after 1 second
  };
  useEffect(() => {
    redirect();
  }, []);
  return null;
};
export default AppStoreOrPlayStoreRedirect;