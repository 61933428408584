import { localStorageKeys, modalTypeKey, ORDER_TYPES } from '@/configs/constants';
import { useAuth } from '@/hooks/useAuth';
import useDeviceLocation from '@/hooks/useDeviceLocation';
import useLocalStorage from '@/hooks/useLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import { getUserFirstAndLastLetter } from '@/lib/helpers/common';
import Profile from '@/pages/profile';
import { isGlobalOrder, isTakeAway } from '@/utils/validations';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import ChevronDown from '../icons/ChevronDown';
import Location from '../icons/Location';
import StaticImg from '../Image/StaticImg';
import CartHeader from './CartHeader';
import { Greetings } from './Greetings';
import LocaleSwitch, { localeSwitchType } from './LocaleSwitch';
const Header = () => {
  const t = useTranslations('Home');
  const router = useRouter();
  const {
    addMultipleParams
  } = useUrlParams();
  const {
    getLocation
  } = useDeviceLocation();
  const {
    getLocaleContent
  } = useAuth();
  const orderData = useSelector(store => store.order)?.order;
  const accountData = useSelector(store => store.account)?.account;
  const userData = useSelector(store => store.auth)?.auth;
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
  const deviceLocation = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION);
  const orderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
  const [showProfile, setShowProfile] = useState(false);
  const {
    firstName,
    lastName
  } = userData || {};
  const handleGetLocation = async () => {
    try {
      await getLocation();
    } catch (error) {
      console.error('Failed to get location:', error.message);
    }
  };
  const getAddress = useCallback(() => {
    const joinParts = (...parts) => parts.filter(Boolean).join(', ');
    if (isTakeAway(orderData.type) && orderData?.store && orderData?.address) {
      const storeName = orderData?.store?.name?.[router.locale] || '';
      const formattedAddress = orderData?.store?.address?.address ? getLocaleContent(orderData?.store?.address?.address) : orderData?.address?.geoData?.formattedAddress || '';
      return joinParts(storeName, formattedAddress);
    }
    const parts = [];
    if (orderData?.address?.houseNumber) {
      parts.push(orderData.address.houseNumber);
    }
    if (orderData?.address?.apartment?.trim()) {
      parts.push(orderData?.address?.apartment);
    }
    const formatted = orderData?.address?.geoData?.formattedAddress;
    if (formatted) {
      parts.push(formatted);
    }
    if (!parts.length && accountData?.deviceLocation?.address) {
      return accountData.deviceLocation.address;
    }
    return joinParts(...parts);
  }, [orderData, accountData]);
  const address = getAddress();
  if (showProfile) {
    return <Profile setShowProfile={setShowProfile} showProfile={showProfile} />;
  }
  const getOrderType = navType => {
    if (navType === 'GLOBAL_ORDER') {
      return isGlobalOrder(orderType) ? orderType : ORDER_TYPES.GLOBAL_DELIVERY;
    } else if (navType === 'FIND_LOCATION') {
      return !isGlobalOrder(orderType) ? orderType : ORDER_TYPES.DELIVERY;
    }
  };
  const handleNavigations = action => {
    switch (action) {
      case 'HOME':
        router.push('/');
        break;
      case 'GET_STARTED':
        router.push('/auth/signin');
        break;
      case 'GLOBAL_ORDER':
        addMultipleParams({
          [modalTypeKey]: 'locationSelector',
          mode: 'globalOrder',
          type: getOrderType('GLOBAL_ORDER')
        });
        break;
      case 'FIND_LOCATION':
        addMultipleParams({
          [modalTypeKey]: 'locationSelector',
          mode: 'searchLocation',
          type: getOrderType('FIND_LOCATION')
        });
        break;
      case 'CATEGORY':
        router.push('/categories');
        break;
      default:
        break;
    }
  };
  const getLocationLabel = () => {
    switch (orderType) {
      case ORDER_TYPES.GLOBAL_DELIVERY:
        return t('global_delivery_to_label');
      case ORDER_TYPES.DELIVERY:
        return t('delivery_to_label');
      case ORDER_TYPES.GLOBAL_TAKEAWAY:
        return t('global_collect_from_label');
      case ORDER_TYPES.TAKEAWAY:
        return t('collect_from_label');
      default:
        return null;
    }
  };
  return <header data-sentry-component="Header" data-sentry-source-file="header.jsx">
            <div className="top_controls_wrapper">
                <div className="container">
                    <div className="mode_switcher">{<LocaleSwitch type={localeSwitchType.SELECT} />}</div>
                    <div className="location_update">
                        <Location data-sentry-element="Location" data-sentry-source-file="header.jsx" />
                        {address ? <button className="current-location-txt" onClick={handleGetLocation}>
                                {getLocationLabel()} <span title={address}>{address}</span>
                            </button> : <button className="current-location-txt" onClick={handleGetLocation}>
                                {getLocationLabel()} <a href="##">{t('select_location')}</a>
                            </button>}
                        {(address || deviceLocation?.isLocationAllowed === false) && <button className="change-btn" onClick={() => handleNavigations('FIND_LOCATION')}>
                                {t('change_label')}
                            </button>}
                    </div>
                </div>
            </div>
            <div className="header_wrapper">
                <div className="container">
                    <div className="logo_wrapper">
                        <Link href="/" data-sentry-element="Link" data-sentry-source-file="header.jsx">
                            <StaticImg src="images/chicking_logo.png" height={50} width={150} alt="chicking_logo" data-sentry-element="StaticImg" data-sentry-source-file="header.jsx" />
                        </Link>
                    </div>
                    <div className="authControls">
                        <CartHeader data-sentry-element="CartHeader" data-sentry-source-file="header.jsx" />

                        <button className="primary_btn category-btn" onClick={() => handleNavigations('CATEGORY')}>
                            {t('category_btn')}
                        </button>
                        <button className="primary_btn" onClick={() => handleNavigations('GLOBAL_ORDER')}>
                            {t('global_order_label')}
                        </button>
                        {!userData?.isUserLoggedIn && <button className="default_btn" onClick={() => handleNavigations('GET_STARTED')}>
                                {t('lets_get_started_label')}
                            </button>}
                        {userData?.isUserLoggedIn && <Link href="#" onClick={() => setShowProfile(true)}>
                                <div className="userDatawrap">
                                    <div className="userIcon">
                                        <div className="avatar">
                                            <div className="avatar_icon">
                                                {/* <User /> */}
                                                <span>{getUserFirstAndLastLetter(firstName, lastName)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <h4>
                                        <small>
                                            {t('hello')} {userData?.firstName || userData?.displayName},{' '}
                                        </small>
                                        <Greetings />
                                    </h4>
                                    <span className="icon chevron">
                                        <ChevronDown />
                                    </span>
                                </div>
                            </Link>}
                    </div>
                </div>
            </div>
        </header>;
};
export default Header;