import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
    version: 'weekly',
    libraries: ['geocoding'],
});
let geocoder = null;

/*
  * Get the Geocoder instance
  * @returns {Promise<Geocoder>}
  */
export async function getGeoCoder() {
    if(!geocoder) {
        const { Geocoder } = await loader.importLibrary('geocoding');
        geocoder = new Geocoder();
    }
    return geocoder;
}
