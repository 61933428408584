import React, { useState } from 'react';
import useCartFoodLines from '@/hooks/useCartData';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import CartBag from '../icons/CartBag';
import CartBagWithoutLeg from '../icons/CartBagWithoutLeg';
import { CommonModal } from '@/components/Modal/CommonModal';
import { useSelector } from 'react-redux';
const CartHeader = () => {
  const router = useRouter();
  const t = useTranslations();
  const authData = useSelector(store => store.auth).auth;
  const [isError, setIsError] = useState(false);
  const {
    cart,
    foodLines
  } = useCartFoodLines();
  const handleRedirect = () => {
    if (!authData?.uid) {
      setIsError('LOGIN_REQUIRED');
      return;
    }
    router.push('/cartitems');
  };
  const handleConfirmInfo = () => {
    if (isError === 'LOGIN_REQUIRED') {
      router.push('/auth/signin');
    }
  };
  const handleCancelInfo = () => {
    setIsError(false);
  };
  if (isError) {
    return <CommonModal title={t('Category.login_required')} message={t('Category.please_login_to_continue')} onConfirm={handleConfirmInfo} onCancel={handleCancelInfo} isOpen={isError} confirmText={t('Category.confirm')} cancelText={t('Category.cancel')} />;
  }
  return <button onClick={handleRedirect} className="cartIconwrap" data-sentry-component="CartHeader" data-sentry-source-file="CartHeader.jsx">
            <div className="cartIcon">
                <button>
                    {cart?.count > 0 && foodLines.length != 0 ? <>
                            <CartBag />
                            <span>{cart?.count}</span>
                        </> : <CartBagWithoutLeg />}
                </button>
            </div>
            {cart?.count > 0 && cart?.itemTotal && cart?.itemTotal !== 0 && foodLines.length != 0 && <h4>
                    {cart?.config?.currency?.currency}{' '}
                    {cart?.itemTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}{' '}
                    <small>
                        {cart?.count}x <span>{t('cart.item_added')}</span>
                    </small>
                </h4>}
        </button>;
};
export default CartHeader;