import useRecentSearches from '@/hooks/useRecentSearches';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';

const RecentSearches = ({ onSelect }) => {
    const t = useTranslations('LocationSelector');
    const { searches } = useRecentSearches('recentSearches');

    if (searches.length === 0) {
        return null;
    }

    return (
        <div className="recent-search">
            <h4>{t('recentSearches')}</h4>
            <div className="recent-search-list">
                {searches.map((search, index) => (
                    <button key={search?.address?.base + index} onClick={() => onSelect(search)}>
                        {search?.address?.base}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default RecentSearches;

RecentSearches.propTypes = {
    onSelect: PropTypes.func.isRequired,
};
