
export const STORAGE_KEY = 'locationSelectorState';


export const FLOW_STEPS = {
    SELECT_LOCATION: 'SELECT_LOCATION',
    SEARCH_LOCATION: 'SEARCH_LOCATION',
    CONFIRM_LOCATION: 'CONFIRM_LOCATION',
    SAVE_LOCATION: 'SAVE_LOCATION',
    SELECT_ADDRESS: 'SELECT_ADDRESS',
}

export const FLOW_ACTIONS = {
    NEXT: 'NEXT',
    PREVIOUS: 'PREVIOUS',
    RESET: 'RESET',
    SET: 'SET'
};

export const FLOW_TYPES = {
    SEARCH_LOCATION: 'searchLocation',
    GLOBAL_ORDER: 'globalOrder',
    SELECT_ADDRESS: 'selectAddress'
}

export const FLOW_TYPE_VARIANTS = {
    DELIVERY: 'DELIVERY',
    TAKEAWAY: 'TAKEAWAY',
    GLOBAL_DELIVERY: 'GLOBAL_DELIVERY',
    GLOBAL_TAKEAWAY: 'GLOBAL_TAKEAWAY'
}

export const COMPONENTS = {
    PLACE_SEARCH: 'placeSearch',
    TABS: 'tabs',
    MAP: 'map',
    DEVICE_LOCATION: 'deviceLocation',
    SAVED_LOCATIONS: 'savedLocations',
    RECENT_SEARCHES: 'recentSearches',
    ADDRESS_FORM: 'addressForm',
    OUTLET_LIST: 'outletList',
    COUNTRY_SELECT: 'countrySelect',
    ADD_NEW_BUTTON: 'addNewButton',
    TIME_SELECTOR: 'timeSelector',
    SAVE_LOCATION_FORM: 'saveLocationForm'
};

export const FLOW_CONFIG = {
    [FLOW_TYPES.SEARCH_LOCATION]: {
        [FLOW_TYPE_VARIANTS.DELIVERY]: {
            maxSteps: 4,
            steps: {
                [FLOW_STEPS.SELECT_LOCATION]: {
                    title: 'selectLocationLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: false,
                        isSubmit: false
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: false,isActive: true },
                    [COMPONENTS.ADD_NEW_BUTTON]: { visible: true,isActive: true },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: true,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    [COMPONENTS.SAVED_LOCATIONS]: { visible: true,isActive: true },
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true }
                },
                [FLOW_STEPS.SEARCH_LOCATION]: {
                    title: 'searchLocation',
                    description: 'description',
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true },
                    backButton: true,
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    footer: {
                        show: false,
                        isSubmit: false
                    },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                },
                [FLOW_STEPS.CONFIRM_LOCATION]: {
                    backButton: true,
                    title: 'confirmLocation',
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'confirmLocation'
                    },
                    description: 'description',
                },
                [FLOW_STEPS.SAVE_LOCATION]: {
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    title: 'saveLocation',
                    description: 'description',
                    backButton: true,
                    [COMPONENTS.ADDRESS_FORM]: { visible: true,isActive: true },
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'saveLocation'
                    },
                    [COMPONENTS.SAVED_LOCATIONS]: { visible: true,isActive: true }
                }
            }
        },
        [FLOW_TYPE_VARIANTS.TAKEAWAY]: {
            maxSteps: 2,
            steps: {
                [FLOW_STEPS.SELECT_LOCATION]: {
                    title: 'selectLocationLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.TABS]: { visible: true, isActive: true },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: true,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true },
                    [COMPONENTS.OUTLET_LIST]: { visible: true,isActive: true }
                },
                [FLOW_STEPS.SEARCH_LOCATION]: {
                    title: 'searchLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.TABS]: { visible: true, isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    [COMPONENTS.OUTLET_LIST]: { visible: true,isActive: true },
                    [COMPONENTS.TIME_SELECTOR]: { visible: true,isActive: true }
                }
            }
        }
    },
    [FLOW_TYPES.GLOBAL_ORDER]: {
        [FLOW_TYPE_VARIANTS.GLOBAL_DELIVERY]: {
            maxSteps: 2,
            steps: {
                [FLOW_STEPS.SELECT_LOCATION]: {
                    title: 'globalOrderDelivery',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'confirmLocation'
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: true,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true },
                    [COMPONENTS.MAP]: { visible: true,isActive: true }
                },
                [FLOW_STEPS.CONFIRM_LOCATION]: {
                    title: 'globalOrderDelivery',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'confirmLocation'
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    [COMPONENTS.ADDRESS_FORM]: { visible: false,isActive: true },
                    [COMPONENTS.SAVED_LOCATIONS]: { visible: false,isActive: true }
                }
            }
        },
        [FLOW_TYPE_VARIANTS.GLOBAL_TAKEAWAY]: {
            maxSteps: 3,
            steps: {
                [FLOW_STEPS.SELECT_LOCATION]: {
                    title: 'globalOrderTakeaway',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: true,isActive: true },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: false,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true },
                    [COMPONENTS.MAP]: { visible: true,isActive: true }
                },
                [FLOW_STEPS.SEARCH_LOCATION]: {
                    title: 'globalOrderTakeaway',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: true,isActive: true },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: false,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.OUTLET_LIST]: { visible: true,isActive: true },
                    [COMPONENTS.TIME_SELECTOR]: { visible: true,isActive: true }
                },
                [FLOW_STEPS.CONFIRM_LOCATION]: {
                    title: 'globalOrderTakeaway',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: true,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    [COMPONENTS.OUTLET_LIST]: { visible: true,isActive: true },
                    [COMPONENTS.TIME_SELECTOR]: { visible: true,isActive: true }
                }
            }
        }
    },
    [FLOW_TYPES.SELECT_ADDRESS]: {
        [FLOW_TYPE_VARIANTS.DELIVERY]: {
            maxSteps: 1,
            steps: {
                [FLOW_STEPS.SELECT_ADDRESS]: {
                    title: 'selectAddressLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.ADD_NEW_BUTTON]: { visible: true,isActive: true },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: false,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: false,
                        isActive: false,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.MAP]: { visible: false,isActive: true },
                    [COMPONENTS.ADDRESS_FORM]: { visible: false,isActive: true },
                    [COMPONENTS.SAVED_LOCATIONS]: { visible: true,isActive: true }
                },
                [FLOW_STEPS.SEARCH_LOCATION]: {
                    title: 'searchLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: false,
                        isSubmit: false
                    },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: false,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true }
                },
                [FLOW_STEPS.CONFIRM_LOCATION]: {
                    title: 'confirmLocation',
                    description: 'description',
                    backButton: true,
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'confirmLocation'
                    },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                },
                [FLOW_STEPS.SAVE_LOCATION]: {
                    title: 'saveLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'saveLocation'
                    },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: false,isActive: true },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    [COMPONENTS.ADDRESS_FORM]: { visible: true,isActive: true },
                    [COMPONENTS.SAVED_LOCATIONS]: { visible: true,isActive: true }
                }
            }
        },
        [FLOW_TYPE_VARIANTS.TAKEAWAY]: {
            maxSteps: 1,
            steps: {
                [FLOW_STEPS.SEARCH_LOCATION]: {
                    title: 'selectAddressLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.TABS]: { visible: false,isActive: true },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: true,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: true
                    },
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true },
                    [COMPONENTS.OUTLET_LIST]: { visible: true,isActive: true },
                    [COMPONENTS.TIME_SELECTOR]: { visible: true,isActive: true }
                }
            }
        },
        [FLOW_TYPE_VARIANTS.GLOBAL_DELIVERY]: {
            maxSteps: 1,
            steps: {
                [FLOW_STEPS.SELECT_ADDRESS]: {
                    title: 'selectAddressLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: true,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    [COMPONENTS.ADDRESS_FORM]: { visible: false,isActive: true },
                    [COMPONENTS.SAVED_LOCATIONS]: { visible: true,isActive: true },
                    [COMPONENTS.ADD_NEW_BUTTON]: { visible: true,isActive: true },
                },
                [FLOW_STEPS.SEARCH_LOCATION]: {
                    title: 'searchLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'saveLocation'
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: true,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    [COMPONENTS.ADDRESS_FORM]: { visible: true,isActive: true },
                }
            }
        },
        [FLOW_TYPE_VARIANTS.GLOBAL_TAKEAWAY]: {
            maxSteps: 1,
            steps: {
                [FLOW_STEPS.SAVE_LOCATION]: {
                    title: 'selectAddressLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'order_now_enjoy_button'
                    },
                    [COMPONENTS.TABS]: { visible: true,isActive: true },
                    [COMPONENTS.COUNTRY_SELECT]: { visible: true,isActive: true },
                    [COMPONENTS.DEVICE_LOCATION]: { visible: false,isActive: true },
                    [COMPONENTS.PLACE_SEARCH]: {
                        visible: true,
                        isActive: true,
                        isInternalDropDown: false
                    },
                    [COMPONENTS.MAP]: { visible: true,isActive: true },
                    [COMPONENTS.OUTLET_LIST]: { visible: true,isActive: true },
                    [COMPONENTS.TIME_SELECTOR]: { visible: true,isActive: true },
                    [COMPONENTS.RECENT_SEARCHES]: { visible: true,isActive: true }
                }
            }
        }
    }
};
