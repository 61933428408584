import Profile from '@/pages/profile';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
function OrderTrackingItem({
  showProfile,
  setShowProfile,
  showOrders,
  setShowOrders,
  orderTrackingLabel
}) {
  const router = useRouter();
  const {
    activeOrderStatus = {}
  } = useSelector(store => store.order) || {};
  const handleOrdserTrackingClick = () => {
    if (activeOrderStatus?.activeOrders?.length === 1) {
      const url = `/order?orderNumber=${activeOrderStatus?.activeOrders[0]?.id}`;
      router.push(url);
    } else {
      setShowProfile(true);
      setShowOrders(true);
    }
  };
  return <>
            <div onClick={handleOrdserTrackingClick} onKeyDown={e => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        setShowProfile(true);
        setShowOrders(true);
      }
    }} style={{
      cursor: 'pointer'
    }}>
                {orderTrackingLabel}
            </div>
            {showProfile && <Profile setShowProfile={setShowProfile} showProfile={showProfile} showOrders={showOrders} />}
        </>;
}
OrderTrackingItem.propTypes = {
  showProfile: PropTypes.bool.isRequired,
  setShowProfile: PropTypes.func.isRequired,
  showOrders: PropTypes.bool.isRequired,
  setShowOrders: PropTypes.func.isRequired,
  orderTrackingLabel: PropTypes.string.isRequired
};
export default OrderTrackingItem;