import { HOME_INFO_TYPES } from '@/configs/constants';
import { setActiveOrderStatus } from '@/redux/slices/order';
import { getOrderStatus } from '@/service/order.service';
import { Timestamp } from 'firebase/firestore';
import path from 'path';

const formatCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliSeconds = now.getMilliseconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${milliSeconds.toString().padStart(2, '0')} ${ampm}`;
};

const getCallerFilePath = () => {
    try {
        const stack = new Error().stack;
        const callerLine = stack?.split('\n')?.[3]?.trim(); // Adjusted index to ensure correct line

        if (callerLine?.includes('at')) {
            const atIndex = callerLine.indexOf('at') + 3;
            const filePath = callerLine.substring(atIndex, callerLine.lastIndexOf(':')).trim();
            return filePath;
        }
    } catch (error) {
        console.error('[DebugLog]: Error extracting caller file path.', error);
    }
    return '';
};

export const debugLog = ({ level = 'warn', message, params = {}, hasTimestamp = false, hasFilePath = false }) => {
    if (process.env.NEXT_PUBLIC_ENV === 'prod') return;

    try {
        let logPrefix = '';

        if (hasTimestamp) {
            const timestamp = hasTimestamp ? `[${formatCurrentTime()}]` : '';
            logPrefix = timestamp;
        }
        if (hasFilePath) {
            const filePath = getCallerFilePath();
            const callerFile = path.basename(filePath.slice(0, 1024));
            const callerFolder = path.basename(path.dirname(filePath.slice(0, 1024)));

            logPrefix += ` [${callerFolder}/${callerFile}]`;
        }

        const logMessage = `${logPrefix} ${message}`;

        switch (level.toLowerCase()) {
            case 'warn':
                console.warn(logMessage, params);
                break;
            case 'error':
                console.error(logMessage, params);
                break;
            default:
                console.warn(logMessage, params);
                break;
        }
    } catch (error) {
        console.error('[DebugLog]: Error in logging logic.', error, { level, message, params });
    }
};

export const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+?\d{7,15}$/;
    return phoneRegex.test(number);
};

export default async function loadMessages(locale) {
    try {
        return (await import(`@/lib/messages/langs/${locale}.json`)).default;
    } catch (error) {
        console.error('Error loading messages:', { ...error, locale });
        return {};
    }
}

export const currencyFormatter = (code, amount) => {
    const currency = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        INR: '₹',
        UAE: 'AED',
    };
    return currency[code] ? `${currency[code]}${amount}` : `${amount} ${code}`;
};

// Get Distance
export const getDistanceFromLatLonInKm = (customerLat, customerLong, outletLat, outletLong) => {
    const R = 6371; // Radius of Earth in km
    const dLat = degToRad(outletLat - customerLat);
    const dLong = degToRad(outletLong - customerLong);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degToRad(customerLat)) * Math.cos(degToRad(outletLat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = roundWith(R * c);
    return distance;
};

function degToRad(deg) {
    return deg * (Math.PI / 180);
}

function roundWith(num, places = 2) {
    return parseFloat(num.toFixed(places));
}

export const calculateBiteCoinAmount = (loyalty, biteCoinConfig) => {
    return parseFloat((loyalty * (biteCoinConfig?.conversion || biteCoinConfig?.coinValue)) / 100) || 0;
};
export const generateHomeData = (homeData, orderType, country) => {
    debugLog({
        message: 'fetchHomeData generateHomeData',
        params: {
            homeData,
        },
    });
    const categoryIds = [];
    const homeDataObject = homeData.reduce((obj, item) => {
        const key = item.type;
        if (!obj[key]) obj[key] = [];

        const isAvailable = checkAvailability(item.type, item.data, orderType, categoryIds, country);
        if (isAvailable) {
            if (item.type === HOME_INFO_TYPES.CATEGORY) {
                categoryIds.push(item.data.id);
            }
            obj[key].push(item.data);
        }
        return obj;
    }, {});

    Object.keys(homeDataObject).forEach((key) => {
        homeDataObject[key].sort((a, b) => a.sortOrder - b.sortOrder);
    });

    return homeDataObject;
};

const checkAvailability = (type, data, orderType, categoryIds, country) => {
    switch (type) {
        case HOME_INFO_TYPES.CHEF_PICKS:
            return isTimedAvailable(data, orderType, country) && categoryIds.includes(data.categoryId);
        case HOME_INFO_TYPES.BANNER:
            return isBannerAvailable(data, country);
        case HOME_INFO_TYPES.CATEGORY:
            return isTimedAvailable(data, orderType, country);
        case HOME_INFO_TYPES.TESTIMONIALS:
            return true; // Testimonials are always available
        default:
            return false;
    }
};

/**
 * Adjusts a UTC date to a specified time zone.
 * @param {Date} date - The UTC date to adjust.
 * @param {string} timeZone - The target time zone (e.g., 'Asia/Kolkata').
 * @returns {Date} - The adjusted date in the specified time zone.
 */
export const convertToTimeZone = (date, timeZone) => {
    return new Date(date.toLocaleString('en-US', { timeZone }));
};

export const isTimedAvailable = (item, orderType, country) => {
    if (item?.isAvailableInStore === false) return false;
    if (!item?.services?.includes(orderType)) return false;

    // Non-timed items are always available
    if (!item?.timed) return true;

    // Check availability based on date and time
    const { start, end } = item?.availableDate || {};
    const currentDate = Timestamp.now();
    const currentDateUtc = new Date(currentDate.seconds * 1000); // Convert to milliseconds for Date
    currentDateUtc.setSeconds(0, 0); // Strip nanoseconds and seconds
    const now = convertToTimeZone(currentDateUtc, country?.timezone);

    // Convert start and end to Date objects if they are string representations of dates
    let startDate = null;
    let endDate = null;

    if (start) {
        startDate = convertToTimeZone(new Date(start.seconds * 1000), country?.timezone);
    }

    if (end) {
        endDate = convertToTimeZone(new Date(end.seconds * 1000), country?.timezone);
    }
    return item?.availableToday && startDate && endDate && startDate <= now && endDate >= now;
};

const isBannerAvailable = (banner, country) => {
    const { startDate, endDate } = banner || {};
    const currentDate = Timestamp.now();
    const currentDateUtc = new Date(currentDate.seconds * 1000); // Convert to milliseconds for Date
    currentDateUtc.setSeconds(0, 0); // Strip nanoseconds and seconds
    const now = convertToTimeZone(currentDateUtc, country?.timezone);

    const start = convertToTimeZone(new Date(startDate.seconds * 1000), country?.timezone);
    const end = convertToTimeZone(new Date(endDate.seconds * 1000), country?.timezone);

    return start <= now && end >= now;
};

export const isFalsy = (value) => {
    if (typeof value === 'function') {
        // Functions are always truthy
        return false;
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    return !value;
};

export const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const parseCoordinates = (coordinates = {}) => {
    if (isFalsy(coordinates)) {
        return {};
    }
    const { lat, lng, longitude, latitude } = coordinates;
    return {
        lat: latitude || lat || null,
        lng: longitude || lng || null,
    };
};

export const getStoreHours = (store) => {
    const { open, close } = store;

    const formatTime = (time) => {
        const [hours, minutes] = time.split(':');
        const ampm = parseInt(hours) >= 12 ? 'PM' : 'AM';
        const formattedHours = (parseInt(hours) % 12 || 12).toString().padStart(2, '0');
        return `${formattedHours}:${minutes} ${ampm}`;
    };

    return {
        open: (open && formatTime(open)) || 'Opening time not available',
        close: (close && formatTime(close)) || 'Closing time not available',
    };
};

export const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':').map(Number); // Split and convert to numbers
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const formatFirebaseTimestamp = (timestamp) => new Date(timestamp.seconds * 1000);
export const transformTimestamps = (data, visited = new WeakSet()) => {
    if (data === null || data === undefined) return data;

    // If it's an instance of Timestamp, convert it to ISO string
    if (data instanceof Timestamp) {
        return data.toDate().toISOString();
    }

    // If it's an object, check if we've already visited this object to avoid circular references
    if (typeof data === 'object') {
        if (visited.has(data)) {
            return data; // Return the object as is to prevent infinite recursion
        }

        visited.add(data); // Mark this object as visited

        // If it's an array, recursively transform each item
        if (Array.isArray(data)) {
            return data.map((item) => transformTimestamps(item, visited));
        }

        // If it's an object, recursively transform each property
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, transformTimestamps(value, visited)])
        );
    }

    return data;
};

export const extractKeys = (obj = {}, keys = []) => {
    return keys.reduce((acc, key) => {
        if (obj[key] !== undefined) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};

export const getSliderSettings = ({ data = [], responsive, slidesToShow = 6 }) => {
    const settings = {
        speed: 500,
        dots: false,
        slidesToShow,
        slidesToScroll: 1,
        cssEase: 'linear',
        arrows: data?.length > slidesToShow,
        infinite: data?.length > slidesToShow,
        autoplay: data?.length > slidesToShow,
        responsive: responsive || [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return settings;
};

export const getOrderStatusDetails = async (authData, dispatch) => {
    try {
        if (!authData?.uid) return;

        const response = await getOrderStatus({ customerId: authData?.uid });
        dispatch(setActiveOrderStatus(response));
    } catch (e) {
        dispatch(
            setActiveOrderStatus({
                activeOrders: [],
            })
        );
    }
};
