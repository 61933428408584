import { isFalsy } from '@/utils';
import { useCallback, useState } from 'react';
import { getGeoCoder } from '@/configs/maps.config';
const useGeocode = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [geoCodeResponse, setGeoCodeResponse] = useState(null);
  const geocode = useCallback(async (params = {}) => {
    if (isFalsy(params)) return;
    setLoading(true);
    setError(null);
    try {
      const geoCoder = await getGeoCoder();
      const data = await geoCoder.geocode(params);
      if (!data.results?.length > 0) return null;
      const result = data.results[0];
      result.geometry = {
        location: {
          lat: result.geometry?.location?.lat(),
          lng: result.geometry?.location?.lng()
        }
      };
      setGeoCodeResponse(result);
      return result || null;
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);
  const cleanFormattedAddress = (address = '') => {
    // Early return if address doesn't contain a comma
    if (!address.includes(',')) return address;

    // Split address into parts
    const parts = address.split(',');
    const firstPart = parts[0].trim();

    // Check if first part LOOKS like a Plus Code:
    // 1. Must contain exactly one + symbol
    // 2. Must be in the correct format
    // 3. Must be followed by a comma
    const isPlusCode = firstPart.split('+').length === 2 &&
    // Has exactly one +
    /^[23456789CFGHJMPQRVWX]{4,6}\+[23456789CFGHJMPQRVWX]{2,3}$/.test(firstPart); // Matches Plus Code character set

    if (isPlusCode) {
      return parts.slice(1).join(',').trim();
    }
    return address;
  };
  return {
    loading,
    error,
    geocode,
    response: geoCodeResponse,
    cleanFormattedAddress
  };
};
export default useGeocode;