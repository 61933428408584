import StaticImg from '@/components/Image/StaticImg';
import React from 'react';
function ComingSoon() {
  return <div className="coming-soon" data-sentry-component="ComingSoon" data-sentry-source-file="ComingSoon.jsx">
            <div className="container">
                <div className="coming-block">
                    <StaticImg src="/images/Group.svg" alt="" data-sentry-element="StaticImg" data-sentry-source-file="ComingSoon.jsx" />
                    <div>
                        <h4>Stay Tuned</h4>
                        <span>Something Delicious is Coming Your Way! </span>
                    </div>
                </div>
            </div>
        </div>;
}
export default ComingSoon;