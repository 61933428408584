import { useAuth } from '@/hooks/useAuth';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import RemoteImg from '../Image/RemoteImg';
import useLocalStorage from '@/hooks/useLocalStorage';
import { localStorageKeys } from '@/configs/constants';
import { useSelector } from 'react-redux';
const chefPickClick = (route, item) => {
  route.push(`/categories?modalType=productDetails&id=${item.id}&mode=create`);
};
function PopulardishSlider({
  data = [],
  handleBeforeChange,
  sliderRef
}) {
  const router = useRouter();
  const {
    getLocaleContent
  } = useAuth();
  const accountData = useSelector(store => store.account).account;
  const storeData = useSelector(store => store.store);
  const currency = storeData?.currentStore?.companyConfig?.currency?.currency || accountData?.selectedCountry?.currency;
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const selectedOrderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
  const getProductPrice = item => {
    if (item?.prices && selectedOrderType) {
      return item?.prices[selectedOrderType.toUpperCase()];
    }
    return item?.defaultSalesPrice;
  };
  const items = data?.map(item => {
    return <div className="item" key={item?.id}>
                <RemoteImg src={item?.chefPickImages?.home?.web} onClick={() => chefPickClick(router, item)} alt={getLocaleContent(item?.name) || 'Dish'} height={400} width={800} />
                <div className="txt-block">
                    <h4>{getLocaleContent(item?.name) || 'Dish'}</h4>
                    <div className={item?.type === 'VEG' ? 'graphic-block-wrap veg' : 'graphic-block-wrap'}>
                        <div className="graphic-block"></div>
                    </div>
                </div>
                <div className="price-box">{`${currency}  ${getProductPrice(item)}`}</div>
            </div>;
  });
  const settings = {
    className: 'center',
    arrows: false,
    centerMode: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    rows: 1,
    nav: true,
    variableWidth: true,
    beforeChange: handleBeforeChange,
    autoplay: true,
    responsive: [{
      breakpoint: 769,
      settings: {
        centerMode: false,
        variableWidth: false
      }
    }]
  };
  return <div className="PopulardishSlider-container" data-sentry-component="PopulardishSlider" data-sentry-source-file="PopulardishSlider.jsx">
            <Slider {...{
      ...settings,
      infinite: data?.length > 1,
      dots: false
    }} ref={sliderRef} data-sentry-element="Slider" data-sentry-source-file="PopulardishSlider.jsx">
                {items}
            </Slider>
        </div>;
}
PopulardishSlider.propTypes = {
  data: PropTypes.array,
  handleBeforeChange: PropTypes.func,
  sliderRef: PropTypes.any
};
export default PopulardishSlider;