import { httpsCallable } from 'firebase/functions';
import { functions } from '@/configs/firebase.config';

export const callCloudFunction = async (functionName, data, locale = 'en') => {
    try {
        const callableFunction = httpsCallable(functions, functionName);
        const request = interceptRequest(data, locale);
        const result = await callableFunction(request);
        return result.data;
    } catch (error) {
        console.error(`Error calling cloud function ${functionName}`, error);
        throw error;
    }
};

const interceptRequest = (data, locale) => {
    const request = {};
    const language = locale;
    const headers = {
        'Accept-Language': language,
    };
    request.body = data;
    request.headers = headers;
    return request;
};
