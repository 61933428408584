import PropTypes from 'prop-types';
import Testimonial from '../Testimonial/Testimonial';
import CommonFooter from './CommonFooter';
const Footer = ({
  locale = 'en'
}) => {
  return <>
            <div className="client-says pt-50">
                <div className="container">
                    <Testimonial locale={locale} data-sentry-element="Testimonial" data-sentry-source-file="Footer.jsx" />
                </div>
            </div>
            <CommonFooter data-sentry-element="CommonFooter" data-sentry-source-file="Footer.jsx" />
        </>;
};
Footer.propTypes = {
  locale: PropTypes.string
};
export default Footer;