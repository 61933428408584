import { debugLog } from '@/utils';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentStore: {},
    nearByStores: [],
};

const storeSlice = createSlice({
    name: 'Store',
    initialState,
    reducers: {
        setCurrentStore(state, action) {
            debugLog({
                message: 'setCurrentStore',
                params: action,
            });
            state.currentStore = action.payload;
        },
        setNearByStores(state, action) {
            state.nearByStores = action.payload;
        },
    },
});

export const { setCurrentStore, setNearByStores } = storeSlice.actions;
export default storeSlice.reducer;
