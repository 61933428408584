import { localStorageKeys } from '@/configs/constants';
import useCartFoodLines from '@/hooks/useCartData';
import useConfirmationModal from '@/hooks/useConfirmationModal';
import useLocalStorage from '@/hooks/useLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import { executeQuery, getCollectionRef, getDocRef, updateSubCollection } from '@/lib/firebase/query';
import { updateCurrentOrder } from '@/redux/slices/order';
import { removeCartTransaction, updateCartAddress } from '@/service/cart';
import { getNearestStore } from '@/service/home.service';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import { addressTypes } from './AddressForm';

const SavedAddresses = ({ onSelect, type, onClose, validateLocationChange }) => {
    const router = useRouter();
    const t = useTranslations('LocationSelector');
    const { cartAddressUpdate } = router.query || {};
    const [savedAddress, setSavedAddress] = useState([]);
    const { actions } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
    const dispatch = useDispatch();
    const authData = useSelector((state) => state.auth)?.auth;
    const { cart: cartData, handleRefresh } = useCartFoodLines();
    const selectedAddress = actions.getNestedValue(localStorageKeys.SELECTED_ADDRESS) || {};
    const currentDeviceData = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION);
    let country;
    if (currentDeviceData?.isLocationAllowed) {
        country = currentDeviceData?.country?.long_name;
    } else {
        let response = actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY);
        country = response?.name;
    }
    const selectedOrderType = actions.getNestedValue(
        `${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`
    );
    const { confirmAction, confirmationModal } = useConfirmationModal();
    const { removeAllParamsExcept } = useUrlParams();
    const getNearestStoreData = async ({ lat, lng }) => {
        try {
            const params = { lat, lng, type: selectedOrderType?.replace('GLOBAL_', '') };
            const response = await getNearestStore(params);
            return response;
        } catch (error) {
            console.error('error', error);
        }
    };

    const getSavedLocations = useCallback(async () => {
        if (!authData.uid) return;
        try {
            const parentDocRef = getDocRef(collections.customers, authData.uid);
            const subCollectionRef = getCollectionRef(subCollections.addresses, parentDocRef);

            const response = await executeQuery({
                ref: subCollectionRef,
                sort: { field: 'createdAt', order: 'desc' },
                conditions: [
                    {
                        field: 'address.country.country',
                        operator: '==',
                        value: country,
                    },
                ],
                pageSize: 20,
            });
            setSavedAddress(response);
        } catch (error) {
            console.error('error', error);
        }
    }, [authData]);

    useEffect(() => {
        getSavedLocations();
    }, [authData]);

    const handleAddressSelect = async (location) => {
        try {
            const dataToValidate = {
                type,
                coordinates: location?.address?.geoData?.coordinates,
            };
            const changeValidateStatus = await validateLocationChange(dataToValidate);
            if (!changeValidateStatus.isValid) {
                const isUserConfirmed = await confirmAction(changeValidateStatus.modalConfig);
                if (!isUserConfirmed) {
                    removeAllParamsExcept(['orderNumber']);
                    return;
                } else {
                    removeCartTransaction(cartData.id, handleRefresh);
                }
            }
            const response = await getNearestStoreData({
                lat: location?.address?.geoData?.coordinates?.lat,
                lng: location?.address?.geoData?.coordinates?.lng,
                type: selectedOrderType,
            });
            const data = {
                isSelected: true,
                ...location,
                companyId: response?.data?.companyId,
                storeId: response?.data?.id,
                paymentTypes: response?.data?.paymentTypes,
                countryCode: response?.data?.companyId,
                address: {
                    ...location.address,
                    addressId: location.id,
                    formatted: `${location.address.houseNumber} ${location.address.apartment}, ${location.address.directions}`,
                },
                outletCode: response?.data?.outletCode,
                companyData: {
                    taxConfig: response?.data?.companyConfig?.taxConfig,
                    currency: response?.data?.companyConfig?.currency,
                    country: response?.data?.companyConfig?.country,
                    deliveryConfig: response?.data?.storeConfig?.deliveryConfig,
                    biteCoinConfig: response?.data?.companyConfig?.biteCoinConfig || '',
                },
            };
            OneSignal.User.addTags({
                LastCompanyId: data.companyId,
                LastStoreId: data.storeId,
            });
            if (!cartAddressUpdate) {
                dispatch(updateCurrentOrder(data));
                setSavedAddress((prev) =>
                    prev.map((loc) => ({
                        ...loc,
                        isSelected: loc.id === location.id,
                    }))
                );
                await onSelect(data);

                const updates = savedAddress.map(async (loc) => {
                    const updatedData = { isSelected: loc.id === location.id };
                    return updateSubCollection({
                        collectionName: collections.customers,
                        documentId: authData?.uid,
                        subCollectionName: subCollections.addresses,
                        subDocumentId: loc.id,
                        updatedData,
                    });
                });
                await Promise.all(updates);
            }
            if (cartData.id && cartAddressUpdate === 'true') {
                await updateCartAddress(cartData.id, data.address, type);
            }
            onClose();
        } catch (error) {
            console.error('Error updating address', error);
        }
    };

    const getFormattedAddress = (address = {}) => {
        const parts = [];
        if (address.houseNumber) {
            parts.push(address.houseNumber);
        }
        if (address?.apartment?.trim()) {
            parts.push(address.apartment);
        }
        if (address.geoData?.formattedAddress) {
            parts.push(address.geoData?.formattedAddress);
        }
        return parts.join(', ');
    };
    if (!savedAddress.length) {
        return null;
    }

    if (confirmationModal) {
        return confirmationModal;
    }

    return (
        <div className="saved-locations">
            <h4>{t('savedLocations')}</h4>
            {savedAddress.map((location) => (
                <button
                    className={`item-locations ${location.isSelected && location.id === selectedAddress.id ? 'isActive' : ''}`}
                    key={location.address.houseNumber}
                    onClick={() => handleAddressSelect(location)}
                >
                    <div className="item-header width-100 ">
                        <div>
                            <h6>
                                {addressTypes.includes(location.address.addressType)
                                    ? t(location.address.addressType)
                                    : location.address.addressType}
                            </h6>
                            <p title={getFormattedAddress(location.address || {})}>
                                {getFormattedAddress(location.address || {})}
                            </p>
                        </div>
                        {location.isSelected && location.id === selectedAddress.id && <button>{t('selected')}</button>}
                    </div>
                </button>
            ))}
        </div>
    );
};

export default SavedAddresses;

SavedAddresses.propTypes = {
    onSelect: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    validateLocationChange: PropTypes.func.isRequired,
};
