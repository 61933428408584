import { localStorageKeys } from '@/configs/constants';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from './useLocalStorage';
const useRecentSearches = (key, limit = 3) => {
  const [searches, setSearches] = useState([]);
  const {
    mode
  } = useRouter().query;
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
  const selectedCountry = actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY);
  const countryCode = selectedCountry?.iso || selectedCountry?.isoCode;
  useEffect(() => {
    if (!mode || !countryCode) return;
    const savedSearches = localStorage.getItem(key);
    if (savedSearches) {
      const data = JSON.parse(savedSearches) || {};
      // Get searches for specific country and mode
      const countryData = data[countryCode] || {};
      setSearches(countryData[mode] || []);
    } else {
      setSearches([]);
    }
  }, [key, mode, countryCode]);
  const addSearch = useCallback(newSearch => {
    if (!mode || !countryCode) return;
    const temp = {
      ...newSearch,
      mode
    };
    setSearches(prevSearches => {
      // Filter out duplicates and add new search at the beginning
      const updatedSearches = [temp, ...prevSearches.filter(search => search.address.base !== temp.address.base)].slice(0, limit);

      // Update localStorage with country-specific data
      const savedSearches = localStorage.getItem(key);
      const allSearches = savedSearches ? JSON.parse(savedSearches) : {};

      // Initialize country data if it doesn't exist
      if (!allSearches[countryCode]) {
        allSearches[countryCode] = {};
      }

      // Update searches for specific country and mode
      allSearches[countryCode][mode] = updatedSearches;
      localStorage.setItem(key, JSON.stringify(allSearches));
      return updatedSearches;
    });
  }, [key, limit, mode, countryCode]);
  return {
    searches,
    addSearch
  };
};
export default useRecentSearches;