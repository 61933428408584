import LikeIcon from '@/components/icons/LikeIcon';
import ProfileHeader from '@/pages/profile/profileHeader';
import { updateAuthData } from '@/redux/slices/auth';
import { removeFromFavourites } from '@/service/favourite.service';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import NoData from '../NoData';
import NoFavourite from '../icons/NoFavourite';
import RemoteImg from '../Image/RemoteImg';
const FavouriteCard = props => {
  const router = useRouter();
  const t = useTranslations('favourite');
  const dispatch = useDispatch();
  const authData = useSelector(store => store.auth).auth;
  const {
    favourites,
    setShowFavouriteCard,
    fetchFavourites
  } = props;
  const handleRemoveFavourites = async ({
    productId
  }) => {
    try {
      await removeFromFavourites(productId, authData?.uid);
      const updatedFavorites = authData.favourites.filter(fav => fav !== productId && fav?.toString() !== productId.toString());
      dispatch(updateAuthData({
        ...authData,
        favourites: updatedFavorites
      }));
      fetchFavourites();
      toast.success(t('favorite_removed'));
    } catch (error) {
      console.error(error);
    }
  };
  const handleExplore = () => {
    props.setShowProfile(false);
    router.push('/categories');
  };
  return <>
            <div className="overlay"></div>
            <section className="drawer fav-food">
                <ProfileHeader setShowProfile={setShowFavouriteCard} showProfile={setShowFavouriteCard} backToProfile={setShowFavouriteCard} title={t('favorite_food')} subtitle={t('favorite_food')} data-sentry-element="ProfileHeader" data-sentry-source-file="index.jsx" />

                <div className="cartitems-list">
                    {favourites.length ? favourites.map(favorite => <div className="cartitem" key={favorite.id}>
                                <div className="img-block">
                                    <RemoteImg src={favorite?.image[0]} alt="" />
                                </div>
                                <div className="cart-details">
                                    <div className="title-box">
                                        <div className="items">
                                            <h4>{favorite.name?.en}</h4>
                                            <div className="quantity-details">
                                                <div className={`graphic-block-wrap ${favorite.type === 'VEG' ? 'veg' : ''}`}>
                                                    <div className="graphic-block"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="like-btn isActive" onClick={() => handleRemoveFavourites(favorite)}>
                                            <LikeIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>) : <NoData title={t('no_favourite_food')} description={t('no_favourite_food_desc')} btnText={t('explore')} icon={<NoFavourite width="3rem" height="3rem" />} btnAction={handleExplore} />}
                </div>
            </section>
        </>;
};
FavouriteCard.propTypes = {
  favourites: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.shape({
      en: PropTypes.string.isRequired
    }).isRequired,
    marketPrice: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired
  })).isRequired,
  setShowFavouriteCard: PropTypes.func.isRequired,
  fetchFavourites: PropTypes.func.isRequired,
  setShowProfile: PropTypes.func.isRequired
};
export default FavouriteCard;