import Link from 'next/link';
import StaticImg from '../Image/StaticImg';
const PolicyHeader = () => {
  return <header data-sentry-component="PolicyHeader" data-sentry-source-file="PolicyHeader.jsx">
            <div className="header_wrapper">
                <div className="container">
                    <div className="logo_wrapper">
                        <Link href="/" data-sentry-element="Link" data-sentry-source-file="PolicyHeader.jsx">
                            <StaticImg src="images/chicking_logo.png" alt="chicking_logo" data-sentry-element="StaticImg" data-sentry-source-file="PolicyHeader.jsx" />
                        </Link>
                    </div>
                </div>
            </div>
        </header>;
};
export default PolicyHeader;