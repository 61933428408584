import CancellationPolicy from '@/components/icons/CancellationPolicy';
import ChevronRight from '@/components/icons/chevronRight';
import PrivacyPolicy from '@/components/icons/PrivacyPolicy';
import TermsConditions from '@/components/icons/TermsConditions';
import RefundPolicy from '@/components/icons/RefundPolicy';
import DeliveryPolicy from '@/components/icons/DeliveryPolicy';
import Link from 'next/link';
import PropTypes from 'prop-types';
const Policy = ({
  onClose,
  t,
  setShowProfile
}) => {
  function handlePolicyClick() {
    setShowProfile(false);
  }
  return <>
            <button className="overlay" onClick={onClose}></button>
            <section className="drawer address-book">
                <div className="sheetHeader">
                    <div className="back-icon">
                        <button className="back-btn" onClick={onClose}>
                            <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="Policy.jsx" />
                        </button>

                        <h4>{t('policy')}</h4>
                    </div>
                </div>
                <div className="settingsSection">
                    <ul>
                        <li>
                            <Link href="/footerItems/policies/terms" onClick={handlePolicyClick} data-sentry-element="Link" data-sentry-source-file="Policy.jsx">
                                <div>
                                    <span className="icon">
                                        <TermsConditions data-sentry-element="TermsConditions" data-sentry-source-file="Policy.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_termsConditions')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="Policy.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="/footerItems/policies/privacyPolicy" onClick={handlePolicyClick} data-sentry-element="Link" data-sentry-source-file="Policy.jsx">
                                <div>
                                    <span className="icon">
                                        <PrivacyPolicy data-sentry-element="PrivacyPolicy" data-sentry-source-file="Policy.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_privacyPolicy')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="Policy.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="/footerItems/policies/refundPolicy" onClick={handlePolicyClick} data-sentry-element="Link" data-sentry-source-file="Policy.jsx">
                                <div>
                                    <span className="icon">
                                        <RefundPolicy data-sentry-element="RefundPolicy" data-sentry-source-file="Policy.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_refundPolicy')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="Policy.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="/footerItems/policies/cancelPolicy" onClick={handlePolicyClick} data-sentry-element="Link" data-sentry-source-file="Policy.jsx">
                                <div>
                                    <span className="icon">
                                        <CancellationPolicy data-sentry-element="CancellationPolicy" data-sentry-source-file="Policy.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_cancellationPolicy')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="Policy.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="/footerItems/policies/deliveryPolicy" onClick={handlePolicyClick} data-sentry-element="Link" data-sentry-source-file="Policy.jsx">
                                <div>
                                    <span className="icon delivery-icon">
                                        <DeliveryPolicy data-sentry-element="DeliveryPolicy" data-sentry-source-file="Policy.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_deliveryPolicy')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="Policy.jsx" />
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        </>;
};
export default Policy;
Policy.propTypes = {
  onClose: PropTypes.func,
  t: PropTypes.func,
  setShowProfile: PropTypes.func
};