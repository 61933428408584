import { localStorageKeys } from '@/configs/constants';
import { auth } from '@/configs/firebase.config';
import useLocalStorage from '@/hooks/useLocalStorage';
import useLocationOnboarding from '@/hooks/useLocationOnboarding';
import { logOut } from '@/lib/auth/authModule';
import { getDocument } from '@/lib/firebase/query';
import ComingSoon from '@/pages/ComingSoon';
import { updateCurrentAccount } from '@/redux/slices/account';
import { setAuthData } from '@/redux/slices/auth';
import { addHomeData, addLoader, removeLoader, setCountries } from '@/redux/slices/common';
import { updateCurrentOrder } from '@/redux/slices/order';
import { setCurrentStore } from '@/redux/slices/stores';
import { resetStore, useAppStore } from '@/redux/store';
import { getCountries, getHomeDataRealtime, getNearestStore } from '@/service/home.service';
import { debugLog, getOrderStatusDetails } from '@/utils';
import { collections } from '@/utils/constants/fb.collections';
import { isDelivery, isTakeAway } from '@/utils/validations';
import { onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import { PropTypes } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import OneSignal from 'react-onesignal';
import { useDispatch, useSelector } from 'react-redux';
import CommonFooter from '../footer/CommonFooter';
import Footer from '../footer/Footer';
import Header from '../header/header';
import PolicyHeader from '../header/PolicyHeader';
import AppStoreOrPlayStoreRedirect from '../redirectForMobile/AppStoreOrPlayStoreRedirect ';
const MainLayout = ({
  children
}) => {
  const dispatch = useDispatch();
  const store = useAppStore();
  const router = useRouter();
  const {
    query
  } = useRouter();
  const blockOtherRoutesForMobile = process.env.NEXT_PUBLIC_BLOCK_FOR_MOBILE;
  const isAuthRoute = router.route.includes('/auth');
  const isPolicyRoute = router.route.includes('/footerItems');
  const isProductLinkSharedRoute = query.modalType === 'productDetails';
  const isPreloadedRef = useRef(false);
  const [isPreloadComplete, setIsPreloadComplete] = useState(false);
  const accountData = useSelector(store => store.account)?.account;
  const orderData = useSelector(store => store.order)?.order;
  const storeData = useSelector(store => store.store);
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const {
    actions: authActions
  } = useLocalStorage(localStorageKeys.AUTH_DATA);
  const authData = useSelector(store => store.auth).auth;
  useLocationOnboarding();
  const selectedOrderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
  useEffect(() => {
    getCountriesData();
    const {
      isLocationAllowed
    } = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION) || {};
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        // no nede to call getCurrentUser here, update redux instead which will call getCurrentUser
        getCurrentUser({
          isDeviceLocationAllowed: !!isLocationAllowed,
          authData: user
        });
      } else {
        handleLogout();
      }
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    debugLog({
      message: 'fetchHomeData storeData',
      params: {
        storeData,
        orderData,
        isPreloadComplete,
        accountData
      }
    });
    initAppStore();
  }, [isPreloadComplete, orderData.type, storeData?.currentStore?.id, orderData?.address?.formatted, orderData?.address?.geoData?.formattedAddress, orderData?.store?.id]);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const {
      isLocationAllowed
    } = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION) || {};
    getCurrentUser({
      isDeviceLocationAllowed: !!isLocationAllowed,
      authData
    });
    getOrderStatusDetails(authData, dispatch);
  }, [authData.uid]);
  const initAppStore = async () => {
    try {
      if (typeof window === 'undefined') {
        return;
      }
      const selectedCountry = actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY);
      const deviceLocation = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION);
      const selectedAddress = actions.getNestedValue(localStorageKeys.SELECTED_ADDRESS);
      let selectedLocation = getSelectedLocation() || {};
      let companyData;
      let store;
      debugLog({
        message: 'fetchHomeData initAppStore selectedLocation',
        params: {
          ...selectedLocation,
          type: selectedAddress?.type
        }
      });
      if (isTakeAway(selectedAddress?.type)) {
        store = await getStoreDataById(selectedAddress?.storeId);
        companyData = {
          taxConfig: store?.companyConfig?.taxConfig,
          currency: store?.companyConfig?.currency,
          country: store?.companyConfig?.country,
          deliveryConfig: store?.storeConfig?.deliveryConfig,
          biteCoinConfig: store?.companyConfig?.biteCoinConfig || ''
        };
      } else if (selectedLocation.lat && selectedLocation.lng) {
        store = (await getNearestStoreData(selectedLocation)) || {};
        companyData = {
          taxConfig: store?.companyConfig?.taxConfig,
          currency: store?.companyConfig?.currency,
          country: store?.companyConfig?.country,
          deliveryConfig: store?.storeConfig?.deliveryConfig,
          biteCoinConfig: store?.companyConfig?.biteCoinConfig || ''
        };
      }
      let newSelectedAddress = {
        ...selectedAddress,
        store,
        companyData
      };
      debugLog({
        message: 'fetchHomeData initAppStore newSelectedAddress',
        params: {
          store,
          deviceLocation,
          selectedCountry,
          newSelectedAddress
        }
      });
      dispatch(updateCurrentAccount({
        deviceLocation,
        selectedCountry
      }));
      dispatch(updateCurrentOrder(newSelectedAddress));
      dispatch(setCurrentStore(store));
      isPreloadedRef.current = true;
      setIsPreloadComplete(true);
      fetchHomeData(accountData?.selectedCountry, orderData?.type);
    } catch (error) {
      console.error(`Error initializing app store: ${error}`);
    }
  };
  const handleLogout = async (showStatusToast = true) => {
    try {
      const loggedInUserId = authActions.getNestedValue(localStorageKeys.USER_ID);
      OneSignal.logout();
      await logOut(showStatusToast);
      const selectedAddress = actions.getNestedValue(localStorageKeys.SELECTED_ADDRESS);
      if (selectedAddress?.id) {
        actions.removeMultipleNested([`${localStorageKeys.SELECTED_ADDRESS}`], [`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`]);
      }
      const dataToClear = [`${localStorageKeys.ACCOUNT_DATA}.${localStorageKeys.DEVICE_LOCATION}`, `${localStorageKeys.ACCOUNT_DATA}.${localStorageKeys.SELECTED_COUNTRY}`];
      actions.clearAllExcept(dataToClear);
      if (loggedInUserId) {
        router.push('/auth/signin');
      }
      resetStore(store);
    } catch (error) {
      console.error(`Error logging out: ${error}`);
    }
  };
  const getCountriesData = async () => {
    try {
      const response = await getCountries();
      const formatted = response.map(({
        iso,
        ...country
      }) => ({
        ...country,
        isoCode: iso
      }));
      dispatch(setCountries(formatted));
    } catch (e) {
      console.error('Error listing countries:', e);
    }
  };
  const getSelectedLocation = () => {
    let location = {};
    debugLog({
      message: 'getSelectedLocation',
      params: {
        accountData,
        orderData
      }
    });
    if (accountData?.deviceLocation?.data) {
      location = accountData?.deviceLocation?.data;
    }
    if (isDelivery(orderData?.type)) {
      if (orderData?.address?.geoData?.coordinates) {
        location = orderData?.address?.geoData?.coordinates;
      }
    } else if (isTakeAway(orderData?.type)) {
      if (orderData?.store?.location?.coordinates) {
        location = orderData?.store?.location?.coordinates;
      }
    }
    return location;
  };
  const getNearestStoreData = async (selectedLocation = {}) => {
    if (Object.keys(selectedLocation || {}).length) {
      try {
        dispatch(addLoader('getNearestStoreData'));
        const response = await getNearestStore({
          ...selectedLocation,
          type: selectedOrderType
        });
        return response?.data;
      } catch (error) {
        console.error('error', error);
      } finally {
        dispatch(removeLoader('getNearestStoreData'));
      }
    }
  };
  const getStoreDataById = async storeId => {
    if (!storeId) return {};
    try {
      dispatch(addLoader('getStoreDataById'));
      const response = await getNearestStore({
        storeId,
        type: orderData[localStorageKeys.SELECTED_ORDER_TYPE]
      });
      return response?.data;
    } catch (error) {
      console.error('error', error);
    } finally {
      dispatch(removeLoader('getStoreDataById'));
    }
  };
  const fetchHomeData = async (country, orderType) => {
    if (!country) return;
    const {
      currentStore
    } = storeData || {};
    const storeParam = currentStore?.id ? currentStore : undefined;
    debugLog({
      message: 'fetchHomeData currentStore',
      params: currentStore
    });
    dispatch(addLoader('fetchHomeData'));
    let listener = getHomeDataRealtime(country, ({
      home,
      companyDetails
    }) => {
      dispatch(removeLoader('fetchHomeData'));
      debugLog({
        message: 'fetchHomeData result',
        params: {
          home,
          companyDetails
        }
      });
      debugLog({
        message: 'fetchHomeData ===================================='
      });
      if (home) {
        dispatch(addHomeData({
          home,
          companyDetails
        }));
      }
    }, orderData, storeParam, orderType);
    return () => listener?.();
  };
  const getCurrentUser = async ({
    isDeviceLocationAllowed,
    authData
  }) => {
    if (!authData?.uid) {
      return;
    }
    try {
      dispatch(addLoader('getCurrentUser'));
      const response = await getDocument(collections.customers, authData.uid);
      if (response) {
        const authData = {
          ...response,
          isDeviceLocationAllowed,
          isUserLoggedIn: true
        };
        dispatch(setAuthData(authData));
      }
      dispatch(removeLoader('getCurrentUser'));
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  function renderContent() {
    // Handle mobile blocked routes
    if (blockOtherRoutesForMobile && !isProductLinkSharedRoute) {
      // Show policy layout for policy routes
      if (isPolicyRoute) {
        return <>
                        <PolicyHeader />
                        {children}
                        <CommonFooter />
                    </>;
      }
      // Show ComingSoon for all other mobile blocked routes
      return <ComingSoon />;
    } else if (blockOtherRoutesForMobile && isProductLinkSharedRoute) {
      return <AppStoreOrPlayStoreRedirect />;
    }

    // Default layout for non-blocked routes
    return <>
                {!isAuthRoute && <Header />}
                {children}
                {!isAuthRoute && <Footer />}
            </>;
  }
  return <>{renderContent()}</>;
};
export default MainLayout;
MainLayout.propTypes = {
  children: PropTypes.element
};