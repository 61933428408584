import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { ORDER_STATUS } from '@/configs/constants';
const orderStatusClass = orderStatus => {
  switch (orderStatus) {
    case ORDER_STATUS.DELIVERED:
    case ORDER_STATUS.COMPLETED:
      return 'statusSuccess';
    case ORDER_STATUS.CANCELLED:
    case ORDER_STATUS.FAILED:
      return 'statusCancel';
    default:
      return 'statusTime';
  }
};
const getDisplayStatus = ({
  status,
  t
}) => {
  switch (status) {
    case ORDER_STATUS.PROCESS:
      return t('order_process');
    case ORDER_STATUS.PLACED:
      return t('order_placed');
    case ORDER_STATUS.ACCEPTED:
      return t('order_accepted');
    case ORDER_STATUS.PREPARING:
      return t('order_preparing');
    case ORDER_STATUS.TRANSIT:
      return t('order_transiting');
    case ORDER_STATUS.DELIVERED:
      return t('order_delivered');
    case ORDER_STATUS.CANCELLED:
      return t('order_cancelled');
    case ORDER_STATUS.REFUND_INITIATED:
      return t('order_refunded');
    case ORDER_STATUS.REFUND_COMPLETED:
      return t('order_refunded');
    case ORDER_STATUS.COMPLETED:
      return t('order_completed');
    default:
      return status;
  }
};
const OrderCard = ({
  orderNumber,
  orderId,
  orderDate,
  status,
  statusTime,
  setShowProfile
}) => {
  const router = useRouter();
  const t = useTranslations('orders');
  const navigateToOrderTracking = () => {
    router.push(`/order?orderNumber=${orderId}`);
    setShowProfile(false);
  };
  return <div className="order-id" data-sentry-component="OrderCard" data-sentry-source-file="OrderCard.jsx">
            <ul>
                <li>
                    <span>{t('order_id')}</span>
                    <span className={router.locale?.startsWith('ar') ? 'takeAwayAr' : ''}>
                        {router.locale?.startsWith('ar') ? orderId?.toUpperCase().split('').reverse().join('') : orderId?.toUpperCase()}
                    </span>
                </li>
                <li>
                    <span>{t('order_number')}</span>
                    <span className={router.locale?.startsWith('ar') ? 'takeAwayAr' : ''}>
                        {router.locale?.startsWith('ar') ? orderNumber.split('').reverse().join('') : orderNumber}
                    </span>
                </li>
                <li>
                    <span>{t('order_date')}</span>
                    <span>{orderDate}</span>
                </li>
                <li>
                    <span className={orderStatusClass(status)}>{getDisplayStatus({
            status,
            t
          })}</span>
                    <span className={router.locale === 'ar' ? 'takeAwayAr statusTime' : 'statusTime'}>
                        {statusTime}
                    </span>
                </li>
                <li>
                    <button className="default_btn" onClick={navigateToOrderTracking}>
                        {t('more_info')}
                    </button>
                    {/* <button className="primary_btn">{t('repeat_order')}</button> */}
                </li>
            </ul>
        </div>;
};
OrderCard.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusTime: PropTypes.string.isRequired,
  setShowProfile: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired
};
export default OrderCard;