import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
const usePlacesAutocomplete = ({
  input,
  skip,
  initialOptions = {}
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNoPlace, setIsNoPlace] = useState(false);
  const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY;
  const optionsRef = useRef(initialOptions); // for options to prevent unnecessary effect triggers
  const timeoutRef = useRef(null); // for the timeout to properly handle cleanup

  const fetchSuggestions = useCallback(async searchInput => {
    if (!searchInput) {
      setSuggestions([]);
      return;
    }
    try {
      setLoading(true);
      setError(null);
      const params = {
        input: searchInput,
        includeQueryPredictions: true,
        ...optionsRef.current
      };
      const response = await axios.post('https://places.googleapis.com/v1/places:autocomplete', params, {
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': API_KEY
        }
      });
      if (response.data.suggestions && response.data.suggestions.length > 0) {
        setSuggestions(response.data.suggestions.map(pred => pred.placePrediction));
        setIsNoPlace(false);
      } else {
        setIsNoPlace(true);
        setSuggestions([]);
      }
    } catch (err) {
      setError(err);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  }, [API_KEY]);
  useEffect(() => {
    optionsRef.current = initialOptions;
  }, [initialOptions]);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!input || skip) {
      setSuggestions([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    setSuggestions([]);
    timeoutRef.current = setTimeout(() => {
      fetchSuggestions(input);
    }, 300);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [input, skip, fetchSuggestions]);
  const clearSuggestions = useCallback(() => {
    setSuggestions([]);
  }, []);
  return {
    suggestions,
    loading,
    error,
    clearSuggestions,
    isNoPlace
  };
};
export default usePlacesAutocomplete;