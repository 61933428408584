import PropTypes from 'prop-types';
import styles from './SkeletonLoader.module.scss';
const formatMargin = margin => {
  if (!margin) return undefined;
  if (typeof margin === 'string') {
    return margin.split(' ').map(m => `${m}rem`).join(' ');
  }
  return `${margin}rem`;
};
const ShapeComponent = ({
  type = 'rectangle',
  width = '100%',
  height = '1rem',
  margin,
  className = ''
}) => {
  const shapeClassMap = {
    circle: styles['skeleton-shape--circle'],
    rectangle: styles['skeleton-shape--rectangle'],
    square: styles['skeleton-shape--square']
  };
  const dynamicStyles = {
    width: typeof width === 'number' ? `${width}%` : width,
    height: typeof height === 'number' ? `${height}%` : height,
    margin: formatMargin(margin)
  };
  const shapeClass = shapeClassMap[type] || shapeClassMap.rectangle;
  return <div className={`
                ${styles['skeleton-shape']}
                ${shapeClass}
                ${className}
            `} style={dynamicStyles} data-sentry-component="ShapeComponent" data-sentry-source-file="SkeltonLoader.jsx" />;
};
const GridComponent = ({
  columns = 3,
  rows = 1,
  gap = '1rem',
  childrens = []
}) => {
  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridTemplateRows: `repeat(${rows}, auto)`,
    gap: typeof gap === 'number' ? `${gap}rem` : gap
  };
  return <div className={styles['skeleton-grid']} style={gridStyles} data-sentry-component="GridComponent" data-sentry-source-file="SkeltonLoader.jsx">
            {childrens.map((child, index) => <SkeletonLoader key={columns + index} layout={[child]} className={styles['skeleton-grid-child']} />)}
        </div>;
};
const processLayoutWithRepeat = layout => {
  const processItem = item => {
    // If the item has a repeat property, generate an array of repeated items
    if (item.repeat) {
      const repeatedItems = Array(item.repeat).fill(null).map(() => {
        const processedItem = {
          ...item
        }; // Create a copy of the item
        delete processedItem.repeat; // Remove the repeat property

        // Recursively process children if they exist
        if (processedItem.children) {
          processedItem.children = processedItem.children.map(processItem);
        }
        return processedItem;
      });
      return repeatedItems;
    }

    // If the item has children, process them recursively
    if (item.children) {
      return {
        ...item,
        children: item.children.map(processItem).flat()
      };
    }
    return item;
  };

  // Flatten the processed layout and remove any null values
  return layout.map(processItem).flat().filter(Boolean);
};
const SkeletonLoader = ({
  layout = [],
  className = ''
}) => {
  const processedLayout = processLayoutWithRepeat(layout);
  return <div className={`${styles['skeleton-container']} ${className}`} data-sentry-component="SkeletonLoader" data-sentry-source-file="SkeltonLoader.jsx">
            {processedLayout.map((item, index) => {
      if (item.type === 'grid') {
        return <GridComponent key={item.columns + index} columns={item.columns} rows={item.rows} gap={item.gap} childrens={item.children || []} />;
      }
      return <ShapeComponent key={item.columns + index} {...item} />;
    })}
        </div>;
};
export default SkeletonLoader;
const LayoutItemPropTypes = PropTypes.shape({
  type: PropTypes.oneOf(['grid', 'rectangle', 'circle', 'square']),
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.arrayOf(PropTypes.object),
  repeat: PropTypes.number
});
SkeletonLoader.propTypes = {
  layout: PropTypes.arrayOf(LayoutItemPropTypes),
  className: PropTypes.string
};
ShapeComponent.propTypes = {
  type: PropTypes.oneOf(['circle', 'rectangle', 'square']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.undefined]),
  className: PropTypes.string
};
GridComponent.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  childrens: PropTypes.arrayOf(PropTypes.object)
};