import { auth } from '@/configs/firebase.config';
import { signOut } from 'firebase/auth';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { useTranslations } from 'next-intl';

export const useAuth = () => {
    const { locale, defaultLocale } = useRouter();
    const t = useTranslations('profile');

    const getLocaleContent = (data = {}) => {
        return data[locale] || data[defaultLocale];
    };

    const logout = async (showStatusToast = true) => {
        try {
            const response = await signOut(auth);
            if (showStatusToast) {
                toast.success(t('user_logout_success_txt'));
            }
            return response;
        } catch (error) {
            toast.error(t('user_logout_error_txt'), error);
            return error;
        }
    };

    return { getLocaleContent, logout };
};
