import { localStorageKeys } from '@/configs/constants';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from './useLocalStorage';

const useUrlParams = () => {
    const router = useRouter();
    const [isReady, setIsReady] = useState(false);
    const { actions } = useLocalStorage(localStorageKeys.APP_SETTINGS);

    useEffect(() => {
        setIsReady(router.isReady);
    }, [router.isReady]);

    const updateUrl = useCallback(
        (newParams, options = {}) => {
            if (typeof window === 'undefined') return;
            try {
                const url = new URL(window.location.href);
                Object.entries(newParams).forEach(([key, value]) => {
                    if (value === null || value === undefined) {
                        url.searchParams.delete(key);
                    } else {
                        url.searchParams.set(key, String(value));
                    }
                });
                url.hash = '';

                const routerOptions = {
                    shallow: options.shallow !== false,
                    ...options,
                };

                if (options.replace) {
                    router.replace(url.toString(), undefined, routerOptions);
                } else {
                    router.push(url.toString(), undefined, routerOptions);
                }
            } catch (error) {
                console.error('Error updating URL:', error);
            }
        },
        [router, isReady]
    );

    const addParam = useCallback(
        (key, value, options) => {
            updateUrl({ [key]: value }, options);
        },
        [updateUrl]
    );

    const updateParam = useCallback(
        (key, value, options) => {
            updateUrl({ [key]: value }, options);
        },
        [updateUrl]
    );

    const removeParam = useCallback(
        (key, options) => {
            updateUrl({ [key]: null }, options);
        },
        [updateUrl]
    );

    const removeAllParams = useCallback(
        (options) => {
            if (typeof window === 'undefined') return;
            try {
                const currentParams = getAllParams();

                const paramsToRemove = Object.keys(currentParams).reduce((acc, key) => {
                    acc[key] = null;
                    return acc;
                }, {});

                updateUrl(paramsToRemove, options);
            } catch (error) {
                console.error('Error removing all parameters:', error);
            }
        },
        [updateUrl]
    );

    const addMultipleParams = useCallback(
        (params, options) => {
            updateUrl(params, options);
        },
        [updateUrl]
    );

    const removeMultipleParams = useCallback(
        (keys, options) => {
            const paramsToRemove = keys.reduce((acc, key) => {
                acc[key] = null;
                return acc;
            }, {});
            updateUrl(paramsToRemove, options);
        },
        [updateUrl]
    );

    const getParam = useCallback((key) => {
        if (typeof window === 'undefined') return null; // Guard for SSR
        const params = new URLSearchParams(window.location.search);
        return params.get(key);
    }, []);

    const getAllParams = useCallback(() => {
        if (typeof window === 'undefined') return {}; // Guard for SSR
        const params = new URLSearchParams(window.location.search);
        return Object.fromEntries(params.entries());
    }, []);

    const removeHashFromUrl = useCallback(() => {
        if (typeof window === 'undefined') return;

        try {
            const url = new URL(window.location.href);
            url.hash = '';
            router.push(url.toString(), undefined, {});
        } catch (error) {
            console.error('Error removing hash from URL:', error);
        }
    }, []);

    const updateLocale = useCallback(
        (selected) => {
            if (typeof window === 'undefined') return;

            const { pathname, query, locale } = router;

            if (selected === locale) return;
            actions.updateNestedValue(localStorageKeys.LOCALE, selected);

            router.push({ pathname, query }, undefined, { locale: selected });
        },
        [router]
    );

    const removeAllParamsExcept = useCallback(
        (paramsToKeep, options) => {
            if (typeof window === 'undefined') return;
            try {
                const currentParams = getAllParams();
                const paramsToRemove = Object.keys(currentParams)
                    .filter((key) => !paramsToKeep.includes(key))
                    .reduce((acc, key) => {
                        acc[key] = null;
                        return acc;
                    }, {});

                if (Object.keys(paramsToRemove).length > 0) {
                    updateUrl(paramsToRemove, options);
                }
            } catch (error) {
                console.error('Error removing parameters except specified ones:', error);
            }
        },
        [updateUrl, getAllParams]
    );

    const addHashToUrl = useCallback(
        (hashValue) => {
            if (typeof window === 'undefined') return;

            const hashString = hashValue.toString().startsWith('#') ? hashValue.toString() : `#${hashValue}`;
            router.push(`${window.location.pathname}${window.location.search}${hashString}`, undefined, {
                shallow: true,
            });
        },
        [router]
    );

    return {
        addParam,
        updateParam,
        removeParam,
        addMultipleParams,
        removeMultipleParams,
        getParam,
        getAllParams,
        isReady,
        router,
        removeHashFromUrl,
        removeAllParams,
        updateLocale,
        addHashToUrl,
        removeAllParamsExcept,
    };
};

export default useUrlParams;
