import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useRouter } from 'next/router';
import Button from '../Button';
import StaticImg from '../Image/StaticImg';
const Carousel = () => {
  const t = useTranslations('Home');
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOrderNow = () => {
    setIsLoading(true);
    router.push('/categories');
  };
  return <div className="carousel_wrapper" data-sentry-component="Carousel" data-sentry-source-file="Carousel.jsx">
            <div className="container">
                <div className="hero_title">
                    <h1>
                        <small> {t('the_chef_label')}</small>
                        {t('the_chef_label_description')}
                    </h1>
                    <span>{t('chef_pick_desc2')}</span>
                    <Button onClick={handleClickOrderNow} isLoading={isLoading} data-sentry-element="Button" data-sentry-source-file="Carousel.jsx">
                        {t('order_now_button')}{' '}
                    </Button>
                </div>
                <div className="carouselImage">
                    <StaticImg src={'images/carouselImage.png'} height={500} width={500} alt="carouselImage" className="carouselImg" data-sentry-element="StaticImg" data-sentry-source-file="Carousel.jsx" />
                </div>
            </div>
        </div>;
};
export default Carousel;