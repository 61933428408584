import { ORDER_STATUS } from '@/configs/constants';
import { firestore } from '@/configs/firebase.config';
import { subCollections } from '@/utils/constants/fb.collections';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    updateDoc,
    where,
} from 'firebase/firestore';

export const getOrderTrackingHistory = async (orderId, setTrackingData) => {
    const ordersCollection = subCollections.orders;
    const orderHistoryCollection = subCollections.orderHistory;

    try {
        // Reference to the document based on orderId
        const orderDocRef = doc(firestore, ordersCollection, orderId);
        const orderSnapshot = await getDoc(orderDocRef);

        if (orderSnapshot.exists()) {
            const orderRef = doc(firestore, ordersCollection, orderSnapshot.id);
            const orderHistoryRef = collection(orderRef, orderHistoryCollection);
            const orderHistoryQuery = query(orderHistoryRef, orderBy('createdAt', 'asc'));

            // Listen for changes in the order history
            const unsubscribe = onSnapshot(
                orderHistoryQuery,
                (querySnapshot) => {
                    const data = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));

                    const uniqueStatuses = Object.values(
                        data.reduce((acc, item) => {
                            acc[item.status] = item;
                            return acc;
                        }, {})
                    );
                    setTrackingData(uniqueStatuses);
                },
                (error) => {
                    console.error('Error listening for changes:', error);
                }
            );

            return { destroy: unsubscribe };
        } else {
            console.error(`No order found with orderId: ${orderId}`);
        }
    } catch (error) {
        console.error('Error in getOrderTrackingHistory: ', error);
        throw error;
    }
};

export const getOrderList = async ({ customerId, limitValue, lastVisibleDoc = {} }) => {
    const ordersSubCollection = subCollections.orders;

    try {
        const ordersQuery = query(
            collection(firestore, ordersSubCollection),
            where('customerId', '==', customerId),
            where('orderStatus', '!=', ORDER_STATUS.PROCESS),
            orderBy('createdAt', 'desc'),
            limit(limitValue),
            startAfter(lastVisibleDoc)
        );

        const ordersSnapshot = await getDocs(ordersQuery);

        const data = ordersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const lastVisible = ordersSnapshot.docs[ordersSnapshot.docs.length - 1];

        return { data, lastVisible };
    } catch (error) {
        console.error('error in getOrderList: ', error);
        throw error;
    }
};

export const getOrderStatus = async ({ customerId }) => {
    const ordersSubCollection = subCollections.orders;

    try {
        const ordersQuery = query(
            collection(firestore, ordersSubCollection),
            where('customerId', '==', customerId),
            where('orderStatus', 'not-in', [
                ORDER_STATUS.PROCESS,
                ORDER_STATUS.CANCELLED,
                ORDER_STATUS.FAILED,
                ORDER_STATUS.COMPLETED,
                ORDER_STATUS.DELIVERED,
            ]),
            orderBy('createdAt', 'desc')
        );

        const ordersSnapshot = await getDocs(ordersQuery);

        const data = ordersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const status = {
            activeOrders: data,
        };
        return status;
    } catch (error) {
        console.error('error in getOrderList: ', error);
    }
};

export const listenToOrderStatus = (orderId, onStatusChange) => {
    if (!orderId) {
        return;
    }

    const orderDocRef = doc(firestore, subCollections.orders, orderId);

    const unsubscribe = onSnapshot(
        orderDocRef,
        (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                onStatusChange(data || {});
            }
        },
        (error) => {
            console.error('Error listening to order status:', error);
        }
    );

    return unsubscribe;
};

export async function updateOrderVisibilityActions(orderId) {
    if (!orderId) return;
    try {
        const orderRef = doc(firestore, subCollections.orders, orderId);

        await updateDoc(orderRef, {
            isVisited: true,
        });

        return true;
    } catch (error) {
        console.error('Error updating order visibility actions:', error);
        return false;
    }
}
