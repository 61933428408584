import { useCallback, useEffect, useMemo, useState } from 'react';
const createPathKeeper = patterns => currentPath => {
  return patterns.some(pattern => pattern instanceof RegExp ? pattern.test(currentPath) : currentPath === pattern);
};
const getKeptValue = (value, pathArray) => {
  return pathArray.reduce((acc, key) => {
    if (!acc || typeof acc !== 'object') return undefined;
    return acc[key];
  }, value);
};
const buildKeptStructure = (value, pathArray) => {
  return pathArray.reduceRight((acc, key, index) => {
    return index === pathArray.length - 1 ? {
      [key]: value
    } : {
      [key]: acc
    };
  }, value);
};
const handleSinglePathKeep = (obj, path, keepKey) => {
  if (typeof keepKey !== 'string' || !keepKey.startsWith(path)) {
    return obj;
  }
  const remainingPath = keepKey.slice(path.length + 1).split('.');
  const baseValue = obj[path];
  if (!baseValue) return obj;
  const keptValue = getKeptValue(baseValue, remainingPath);
  if (keptValue === undefined) return obj;
  const newStructure = buildKeptStructure(keptValue, remainingPath);
  return {
    ...obj,
    [path]: newStructure
  };
};
const deletePath = (object, path, shouldKeepPath) => {
  const parts = path.split('.');
  const lastPart = parts.pop();
  const target = parts.reduce((acc, part) => acc?.[part], object);
  if (target && !shouldKeepPath(path)) {
    delete target[lastPart];
  }
};
const processMultiplePaths = (obj, pathsToProcess, shouldKeepPath) => {
  const processedValue = {
    ...obj
  };
  pathsToProcess.forEach(path => deletePath(processedValue, path, shouldKeepPath));
  return processedValue;
};
const cleanupEmpty = (obj, basePath, shouldKeep) => {
  if (!obj || typeof obj !== 'object') return obj;
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const currentPath = basePath ? `${basePath}.${key}` : key;
    if (shouldKeep(currentPath)) {
      acc[key] = value;
      return acc;
    }
    if (value && typeof value === 'object') {
      const cleanedValue = cleanupEmpty(value, currentPath, shouldKeep);
      if (Object.keys(cleanedValue).length > 0) {
        acc[key] = cleanedValue;
      }
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};
const useLocalStorage = (key, initialValue = {}) => {
  const [storedValue, setStoredValue] = useState(() => getStorageValue(key, initialValue));
  const [isInitialized, setIsInitialized] = useState(false);

  // Move getStorageValue outside to make it available throughout the hook
  function getStorageValue(storageKey, defaultValue) {
    if (typeof window === 'undefined') {
      return defaultValue;
    }
    try {
      const item = window.localStorage.getItem(storageKey);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return defaultValue;
    }
  }
  useEffect(() => {
    setIsInitialized(true);
    const handleStorageChange = event => {
      if (event.key === key) {
        try {
          const newValue = event.newValue ? JSON.parse(event.newValue) : initialValue;
          setStoredValue(newValue);
        } catch (error) {
          console.error('Error handling storage change:', error);
        }
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [key, initialValue]);
  const setValue = useCallback((valueKey, value) => {
    try {
      const currentValue = getStorageValue(key, initialValue);
      const valueToStore = typeof value === 'function' ? value(currentValue[valueKey] || {}) : value;
      const newStoredValue = {
        ...currentValue,
        [valueKey]: valueToStore
      };
      window.localStorage.setItem(key, JSON.stringify(newStoredValue));
      setStoredValue(newStoredValue);
    } catch (error) {
      console.error('Error setting localStorage value:', error);
    }
  }, [key, initialValue]);
  const setValueAtNestedPath = useCallback((storageKey, path, value) => {
    try {
      const currentValue = getStorageValue(storageKey, {}); // Get the current value of the specified storage key
      const pathArray = path.split('.'); // Split the path into an array (e.g., ['selectedAddress', 'order', 'scheduledTime'])
      let updatedValue = {
        ...currentValue
      }; // Clone the current value

      // Traverse the path and update the nested property
      let temp = updatedValue;
      pathArray.forEach((p, index) => {
        if (index === pathArray.length - 1) {
          temp[p] = value; // Set the new value at the specified path
        } else {
          temp[p] = temp[p] || {}; // Ensure intermediate levels exist
          temp = temp[p];
        }
      });

      // Store the updated value back in localStorage
      window.localStorage.setItem(storageKey, JSON.stringify(updatedValue));
      setStoredValue(updatedValue); // Update state with the new value
    } catch (error) {
      console.error('Error setting nested value in localStorage:', error);
    }
  }, []);
  const updateNestedValue = useCallback((path, value) => {
    try {
      const currentValue = getStorageValue(key, initialValue);
      const newValue = JSON.parse(JSON.stringify(currentValue));
      const pathArray = path.split('.');
      let current = newValue;
      for (let i = 0; i < pathArray.length - 1; i++) {
        const pathKey = pathArray[i];
        if (!current[pathKey]) {
          current[pathKey] = {};
        }
        current = current[pathKey];
      }
      current[pathArray[pathArray.length - 1]] = value;
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
    } catch (error) {
      console.error('Error updating nested value:', error);
    }
  }, [key, initialValue]);
  const getNestedValue = useCallback(path => {
    // Always get fresh data from localStorage
    const freshData = getStorageValue(key, initialValue);
    const pathArray = path.split('.');
    let current = freshData;
    for (const pathKey of pathArray) {
      if (current === undefined || current === null) {
        return undefined;
      }
      current = current[pathKey];
    }
    return current;
  }, [key, initialValue]);
  const cleanupEmptyObjects = (obj, pathArray) => {
    const newObj = JSON.parse(JSON.stringify(obj));
    let current = newObj;
    for (let i = 0; i < pathArray.length - 1; i++) {
      const pathKey = pathArray[i];
      if (Object.keys(current[pathKey]).length === 0) {
        delete current[pathKey];
        break;
      }
      current = current[pathKey];
    }
    return newObj;
  };
  const removeValue = useCallback(path => {
    try {
      const currentValue = getStorageValue(key, initialValue);
      const newValue = JSON.parse(JSON.stringify(currentValue));
      const pathArray = path.split('.');
      if (pathArray.length === 1) {
        delete newValue[path];
        window.localStorage.setItem(key, JSON.stringify(newValue));
        setStoredValue(newValue);
        return;
      }
      let current = newValue;
      for (let i = 0; i < pathArray.length - 1; i++) {
        if (!current[pathArray[i]]) return;
        current = current[pathArray[i]];
      }
      delete current[pathArray[pathArray.length - 1]];
      const cleanedValue = cleanupEmptyObjects(newValue, pathArray);
      window.localStorage.setItem(key, JSON.stringify(cleanedValue));
      setStoredValue(cleanedValue);
    } catch (error) {
      console.error('Error removing value:', error);
    }
  }, [key, initialValue]);
  const clearAllExcept = useCallback(() => {
    try {
      // Get the current data from localStorage
      const currentData = getStorageValue(key, initialValue) || {};
      window.localStorage.clear();

      // Set new data with only accountData in localStorage
      window.localStorage.setItem(key, JSON.stringify(currentData));
    } catch (error) {
      console.error('Error clearing localStorage except for accountData:', error);
    }
  }, [key, initialValue]);
  const clearAll = useCallback(() => {
    try {
      window.localStorage.clear();
      setStoredValue(initialValue);
    } catch (error) {
      console.error('Error clearing all localStorage data:', error);
    }
  }, [initialValue]);
  const removeMultipleNested = useCallback((paths, keepKeys = []) => {
    if (!Array.isArray(paths)) {
      throw new Error('paths must be an array');
    }
    if (!Array.isArray(keepKeys)) {
      throw new Error('keepKeys must be an array');
    }
    try {
      const currentValue = getStorageValue(key, initialValue);
      if (!currentValue) return;
      const newValue = JSON.parse(JSON.stringify(currentValue));
      const shouldKeepPath = createPathKeeper(keepKeys);

      // Handle single path case
      if (paths.length === 1 && keepKeys.length === 1) {
        const processedValue = handleSinglePathKeep(newValue, paths[0], keepKeys[0]);
        if (Object.keys(processedValue).length === 0) return;
        window.localStorage.setItem(key, JSON.stringify(processedValue));
        setStoredValue(processedValue);
        return;
      }

      // Handle multiple paths
      let processedValue = processMultiplePaths(newValue, paths, shouldKeepPath);
      processedValue = cleanupEmpty(processedValue, '', shouldKeepPath);
      if (Object.keys(processedValue).length === 0) return;
      window.localStorage.setItem(key, JSON.stringify(processedValue));
      setStoredValue(processedValue);
    } catch (error) {
      console.error('Error in removeMultipleNested:', error);
      throw error;
    }
  }, [key, initialValue]);
  const actions = useMemo(() => ({
    setValue,
    updateNestedValue,
    getNestedValue,
    removeValue,
    clearAll,
    clearAllExcept,
    removeMultipleNested,
    setValueAtNestedPath,
    getStorageValue: storageKey => getStorageValue(storageKey, initialValue)
  }), [setValue, updateNestedValue, getNestedValue, removeValue, clearAll, clearAllExcept, initialValue]);
  return {
    storedValue,
    actions,
    isInitialized
  };
};
export default useLocalStorage;