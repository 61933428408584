import { modalTypes } from '@/components/ModalWrapper';

export const localStorageKeys = {
    LOGGED_IN_USER: 'loggedInUser',
    SELECTED_COUNTRY: 'selectedCountry',
    SELECTED_ORDER_TYPE: 'type',
    ACCOUNT_DATA: 'accountData',
    ACCOUNT_CONFIG: 'accountConfig',
    DEVICE_LOCATION: 'deviceLocation',
    DEFAULT_ADDRESS: 'defaultAddress',
    SELECTED_ADDRESS: 'selectedAddress',
    AUTH_DATA: 'authData',
    COUPON: 'coupon',
    BITE_COIN_STATUS: 'biteCoinStatus',
    PAYMENT_LOADER_STATUS: 'paymentloaderstatus',
    APP_SETTINGS: 'appSettings',
    LOCALE: 'locale',
    USER_ID: 'userId',
};

export const modalTypeKey = 'modalType';

export const ORDER_TYPES = {
    DELIVERY: 'DELIVERY',
    TAKEAWAY: 'TAKEAWAY',
    GLOBAL_DELIVERY: 'GLOBAL_DELIVERY',
    GLOBAL_TAKEAWAY: 'GLOBAL_TAKEAWAY',
};

export const addressTypes = {
    SAVED_ADDRESS: 'saved_address',
    TEMPERORARY_ADDRESS: 'temporary_address',
    CUSTOM_ADDRESS: 'custom_address',
};

export const addressSaveTypes = {
    TEMPORARY: 'Temporary',
    NEW_ADDRESS: 'NEW_ADDRESS',
};

export const ROUTES = {
    public: ['/auth/signin', '/', '/categories'],
    modals: [...Object.keys(modalTypes)],
};

export const PAYMENT_STATUS = {
    NEW: 'NEW',
    COD: 'COD',
    PAID: 'PAID',
    PENDING: 'PENDING',
};

export const ORDER_STATUS = {
    PROCESS: 'PROCESS',
    PLACED: 'PLACED',
    ACCEPTED: 'ACCEPTED',
    PREPARING: 'PREPARING',
    TRANSIT: 'TRANSIT',
    DELIVERED: 'DELIVERED',
    READY: 'READY',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
    REFUND_INITIATED: 'REFUND_INITIATED',
    REFUND_COMPLETED: 'REFUND_COMPLETED',
    PROCESS_VALUE: 'order_initiated',
    PLACED_VALUE: 'order_placed',
    ACCEPTED_VALUE: 'order_accepted',
    PREPARING_VALUE: 'order_preparing',
    TRANSIT_VALUE: 'order_transiting',
    DELIVERED_VALUE: 'order_delivered',
    READY_VALUE: 'order_ready',
    COMPLETED_VALUE: 'order_completed',
    CANCELLED_VALUE: 'order_cancelled',
    FAILED_VALUE: 'order_failed',
    REFUND_INITIATED_VALUE: 'refund_initiated',
    REFUND_COMPLETED_VALUE: 'refund_completed',
};

export const OfferTypes = {
    PERCENTAGE: 'PERCENTAGE',
    FLAT: 'FLAT',
    FREE_DELIVERY: 'FREE_DELIVERY',
};

export const PromoStatus = {
    ACTIVE: 'ACTIVE',
};

export const Operation = {
    increment: 'increment',
};

export const TaxType = {
    CGST: 'CGST',
    SGST: 'SGST',
    VAT: 'VAT',
    GST: 'GST',
    NO_TAX: 'NO_TAX',
    INCLUSIVE: 'Inclusive',
    EXCLUSIVE: 'Exclusive',
};

export const CouponType = {
    FLAT: 'FLAT',
    PERCENTAGE: 'PERCENTAGE',
    FREE_DELIVERY: 'FREE_DELIVERY',
};

export const PRODUCT_TYPES = {
    VEG: 'Veg',
    NON_VEG: 'Non Veg',
};
export const API_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL;

export const MOD_GROUP_TYPES = {
    VARIANT: 'variant',
    ADDON: 'addon',
};

export const HOME_INFO_TYPES = {
    BANNER: 'BANNER',
    CATEGORY: 'CATEGORY',
    CHEF_PICKS: 'CHEF_PICKS',
    TESTIMONIALS: 'TESTIMONIALS',
};

export const PRODUCT_MODE = {
    CREATE: 'create',
    EDIT: 'edit',
};

export const PRODUCT_MODIFIER = {
    MODIFIER: 'MODIFIER',
    WITHOUT_MODIFIER: 'WITHOUT_MODIFIER',
};

export const PAYMENT_TYPES = {
    ONLINE: 'ONLINE',
    COD: 'COD',
};

export const PAYMENT_GATEWAY = {
    NETWORK_INTERNATIONAL: 'NETWORK_INTERNATIONAL',
};
