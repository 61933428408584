import { ORDER_TYPES, localStorageKeys } from '@/configs/constants';
import useConfirmationModal from '@/hooks/useConfirmationModal';
import useLocalStorage from '@/hooks/useLocalStorage';
import useReverseGeocoding from '@/hooks/useReverseGeocoding';
import { addSubCollection, deleteDocument, executeQuery, getCollectionRef, getDocRef, updateDocument } from '@/lib/firebase/query';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import { isGlobalOrder, validateField } from '@/utils/validations';
import { serverTimestamp } from 'firebase/firestore';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../Button';
import ChevronRight from '../icons/chevronRight';
import CloseIcon from '../icons/CloseIcon';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';
import { takeAwayDetails } from '../loader/SkeltonLoader/loaderConfigs';
import SkeletonLoader from '../loader/SkeltonLoader/SkeltonLoader';
import AddressForm from '../LocationSelector/AddressForm';
import Delivery from '../LocationSelector/components/Delivery';
import { FLOW_TYPES } from '../LocationSelector/config';
import { parseAddressResponse } from '../LocationSelector/DataFormatter';
import useLocationForm from '../LocationSelector/hooks/useLocationForm';
import { useModalConfig } from '../LocationSelector/hooks/useModalConfig';
import LocationMap from '../LocationSelector/LocationMap';
const AddressBooks = ({
  onClose
}) => {
  const [addresses, setAddresses] = useState([]);
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
  const currentDeviceData = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION);
  let country, isoCode;
  if (currentDeviceData?.isLocationAllowed) {
    country = currentDeviceData?.country?.long_name;
    isoCode = currentDeviceData?.country?.short_name;
  } else {
    let response = actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY);
    country = response.name;
    isoCode = response?.isoCode;
  }
  const [confirmLocation, setConfirmLocation] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newAddress, setNewAddress] = useState({});
  const [addNewAddress, setAddNewAddress] = useState(null);
  const [errors, setErrors] = useState({});
  const [defaultLocation, setDefaultLocation] = useState(null);
  const t = useTranslations('profile');
  const mode = FLOW_TYPES.SEARCH_LOCATION;
  const authData = useSelector(state => state.auth)?.auth;
  const {
    confirmAction,
    confirmationModal
  } = useConfirmationModal();
  const isAddressEmpty = obj => Object.keys(obj).length === 0;
  const {
    formData,
    handleSearchComplete,
    handleSuggestionSelect
  } = useLocationForm();
  const {
    fetchAddresses
  } = useReverseGeocoding();
  const commonData = useSelector(store => store.common);
  const currentDeviceLocationData = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION);
  const selectedCountry = actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY);
  const type = ORDER_TYPES.DELIVERY;
  const {
    currentConfig = {}
  } = useModalConfig(mode, type);
  const getSavedLocations = useCallback(async () => {
    setIsLoading(true);
    try {
      const parentDocRef = getDocRef(collections.customers, authData.uid);
      const subCollectionRef = getCollectionRef(subCollections.addresses, parentDocRef);
      const response = await executeQuery({
        ref: subCollectionRef,
        sort: {
          field: 'createdAt',
          order: 'desc'
        },
        conditions: [{
          field: 'address.country.country',
          operator: '==',
          value: country
        }],
        pageSize: 20
      });
      setAddresses(response);
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    getSavedLocations();
  }, [getSavedLocations]);
  useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.closest('.dropdown-btn')) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);
  useEffect(() => {
    setNewAddress(editingAddress?.address || {});
  }, [editingAddress]);
  const handleChange = (name, value) => {
    setNewAddress({
      ...newAddress,
      [name]: value
    });
    const isValid = validateData({
      address: {
        ...newAddress,
        [name]: value
      }
    });
    if (isValid.errors && addNewAddress) {
      setErrors(prev => {
        const newErrors = {
          ...prev
        };
        ['directions', 'houseNumber', 'name', 'phone'].forEach(key => {
          delete newErrors[key];
        });
        return newErrors;
      });
    }
    if (isValid.errors) {
      setErrors(isValid.errors);
    }
  };
  const validateData = formattedData => {
    const {
      address
    } = formattedData;
    const errors = {};
    const fieldsToValidate = ['houseNumber', 'name', 'phone'];
    fieldsToValidate.forEach(field => {
      const fieldStatus = validateField({
        name: field,
        value: address[field],
        errors,
        formData: formattedData
      });
      if (!fieldStatus.isValid) {
        errors[field] = fieldStatus.errors[field];
      }
    });
    return {
      errors,
      status: Object.values(errors).filter(i => i).length === 0
    };
  };
  const handleDelete = useCallback(async addressId => {
    try {
      const confirmModalConfig = {
        title: t('deleteAddress'),
        message: t('deleteAddressDescription'),
        confirmText: t('yesLablel'),
        cancelText: t('noLabel')
      };
      const userConfirmation = await confirmAction(confirmModalConfig);
      if (userConfirmation) {
        setIsLoading(true);
        const path = `${collections.customers}/${authData.uid}/${subCollections.addresses}`;
        await deleteDocument(path, addressId);
        getSavedLocations();
        toast.success(t('successDeletingAddress'));
      }
    } catch (error) {
      toast.error(t('errorDeletingAddress'));
      console.error('error', error);
    } finally {
      setIsLoading(false);
      setOpenDropdownId(null);
    }
  }, []);
  const prepareAddressData = (data, isUpdate) => {
    const structuredData = {
      address: {
        name: data.name,
        phone: data.phone,
        houseNumber: data.houseNumber,
        apartment: data.apartment || '',
        directions: data.directions || '',
        addressType: data.addressType || '',
        geoData: {
          formattedAddress: data?.geoData?.formattedAddress || '',
          coordinates: {
            ...data?.geoData?.coordinates
          }
        },
        country: {
          country,
          countryCode: isoCode
        }
      },
      sOrder: serverTimestamp()
    };
    if (!isUpdate) {
      structuredData.createdAt = serverTimestamp();
    }
    return structuredData;
  };
  const handleUpdate = useCallback(async () => {
    setIsLoading(true);
    try {
      let updatedData = prepareAddressData(newAddress, true);
      updatedData = {
        ...updatedData,
        address: {
          ...updatedData.address,
          addressType: updatedData.address.addressType || 'other'
        }
      };
      const path = `${collections.customers}/${authData.uid}/${subCollections.addresses}`;
      await updateDocument(path, editingAddress.id, updatedData);
      setEditingAddress(null);
      getSavedLocations();
      toast.success(t('successUpdatingAddress'));
    } catch (error) {
      toast.error(t('errorUpdatingAddress'));
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  }, [editingAddress, newAddress]);
  const handleAddAddress = useCallback(async () => {
    setIsLoading(true);
    const isValid = validateData({
      address: {
        ...newAddress
      }
    });
    setErrors(isValid.errors);
    if (Object.keys(isValid.errors).length > 0) {
      setIsLoading(false);
      return;
    }
    try {
      let updatedData = prepareAddressData(newAddress);
      updatedData = {
        ...updatedData,
        address: {
          ...updatedData.address,
          addressType: updatedData.address.addressType || 'other'
        }
      };
      await addSubCollection({
        collectionName: collections.customers,
        documentId: authData.uid,
        subCollectionName: subCollections.addresses,
        subCollectionData: updatedData
      });
      setAddNewAddress(null);
      getSavedLocations();
      toast.success(t('successAddingAddress'));
    } catch (error) {
      toast.error(t('errorAddingAddress'));
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  }, [addNewAddress, newAddress]);
  const toggleDropdown = useCallback(addressId => {
    setOpenDropdownId(prev => prev === addressId ? null : addressId);
  }, []);
  const handleEditClick = useCallback(address => {
    setEditingAddress(address);
    setOpenDropdownId(null);
  }, []);
  const handleAddAddressClick = useCallback(() => {
    setAddNewAddress(true);
    setNewAddress({
      phone: authData?.phoneNumber
    });
    setOpenDropdownId(null);
  }, []);
  const formatAddress = useCallback(address => {
    const parts = [];
    if (address.houseNumber) parts.push(address.houseNumber);
    if (address?.apartment?.trim()) parts.push(address.apartment);
    if (address.geoData?.formattedAddress && typeof address.geoData.formattedAddress === 'string') {
      parts.push(address.geoData.formattedAddress);
    } else {
      parts.push(address.geoData.formattedAddress?.place?.formattedAddress);
    }
    return parts.join(', ');
  }, []);
  const renderAddressItem = useCallback(address => <div key={address.id} className="item-locations" style={{
    cursor: 'pointer'
  }}>
                <div className="item-header">
                    <div>
                        <h6>{address.address.name}</h6>
                        <p>{formatAddress(address.address)}</p>
                    </div>
                    {address.address.addressType && <span>
                            {address.address.addressType.replace(/^./, firstLetter => firstLetter.toUpperCase())}
                        </span>}
                </div>
                <div className="dropdown-block">
                    <div className="dropdown-btn">
                        <button className="menu-dot-icon" onClick={e => {
          e.stopPropagation();
          toggleDropdown(address.id);
        }}>
                            <ThreeDotsIcon />
                        </button>
                        {openDropdownId === address.id && <div className="dropdown">
                                <ul>
                                    <li>
                                        <button onClick={e => {
                e.preventDefault();
                handleEditClick(address);
              }}>
                                            {t('edit')}
                                        </button>
                                    </li>
                                    <li className="delete-btn">
                                        <button onClick={e => {
                e.stopPropagation();
                handleDelete(address.id);
              }}>
                                            {t('delete')}
                                        </button>
                                    </li>
                                </ul>
                            </div>}
                    </div>
                </div>
            </div>, [openDropdownId, toggleDropdown, handleEditClick, handleDelete, formatAddress]);
  const getCoordinates = useCallback((addressCoordinates, defaultLoc) => {
    if (defaultLoc) {
      return {
        lat: defaultLoc.data.latitude,
        lng: defaultLoc.data.longitude
      };
    } else if (addressCoordinates && Object.keys(addressCoordinates).length > 0) {
      return addressCoordinates;
    } else {
      return null;
    }
  }, []);
  const updateLocationData = (coordinates, formattedAddress = '') => {
    setDefaultLocation({
      data: {
        latitude: coordinates.lat,
        longitude: coordinates.lng
      }
    });
    handleChange('geoData', {
      formattedAddress,
      coordinates: {
        lat: coordinates.lat,
        lng: coordinates.lng
      }
    });
    setErrors({});
  };
  const handlePlaceSelect = selectedPlace => {
    setConfirmLocation(true);
    const formattedAddress = parseAddressResponse(selectedPlace);
    updateLocationData(formattedAddress?.address?.geoData?.coordinates, formattedAddress?.address?.geoData?.formattedAddress);
  };
  const confirmCurrentLocation = async locationCoordinates => {
    const formattedAddress = await fetchAddresses([Object.values(locationCoordinates).join(',')]);
    setConfirmLocation(true);
    updateLocationData(locationCoordinates, formattedAddress[0]?.formatted_address);
  };
  if (confirmationModal) {
    return confirmationModal;
  }
  const getCountry = () => {
    if (isGlobalOrder(type)) {
      return formData.country;
    }
    let selectedCountryDetails = null;
    if (currentDeviceLocationData?.isLocationAllowed) {
      selectedCountryDetails = commonData.countries.find(i => i.id?.toUpperCase() === currentDeviceLocationData?.country?.short_name?.toUpperCase());
    } else {
      selectedCountryDetails = commonData.countries.find(i => i.id?.toUpperCase() === selectedCountry?.id?.toUpperCase());
    }
    return selectedCountryDetails;
  };
  if (editingAddress || addNewAddress) {
    return <>
                <button className="overlay" onClick={onClose}></button>
                <section className="drawer address-book">
                    <div className="sheetHeader">
                        <h4>
                            {editingAddress ? `${t('address_edit_title')}` : `${t('address_create_title')}`}{' '}
                            <small>
                                {' '}
                                {editingAddress ? `${t('update')}` : `${t('add')}`} {t('address_description')}
                            </small>
                        </h4>
                        <button className="closeBtn" onClick={() => {
            setConfirmLocation(null);
            setDefaultLocation(null);
            if (editingAddress) {
              setEditingAddress(null);
            } else {
              setAddNewAddress(null);
              setNewAddress({});
              setErrors({});
            }
          }}>
                            {t('back')}
                        </button>
                    </div>
                    <div className="location-wrap">
                        <Delivery getCountry={getCountry} formData={formData} handleChange={() => {}} errors={errors} config={currentConfig} onClose={() => {}} handleSearchComplete={handleSearchComplete} handleSuggestionSelect={handleSuggestionSelect} goToNextStep={() => {}} mode={mode} handleBackClick={() => {}} validateField={validateField} handlePlaceSelect={handlePlaceSelect} updateCoordinates={confirmCurrentLocation} handleAddNewAddress={() => {}} currentStep={() => {}} shouldSearch={true} setShouldSearch={() => {}} />
                        {(confirmLocation && !editingAddress || editingAddress) && <>
                                <LocationMap onLocationChange={coordinates => {
              setDefaultLocation({
                data: {
                  latitude: coordinates?.lat,
                  longitude: coordinates?.lng
                }
              });
              handleChange('geoData', {
                formattedAddress: '',
                coordinates: coordinates
              });
            }} coordinates={getCoordinates(editingAddress?.address?.geoData?.coordinates, defaultLocation)} preventZoom={mode === FLOW_TYPES.GLOBAL_ORDER} preventMarkerMovement={mode === FLOW_TYPES.GLOBAL_ORDER} />
                                <AddressForm currentAddress={newAddress} handleFieldChange={handleChange} validateField={validateField} errorsData={errors} isNewAddress={addNewAddress} />
                                <Button className="submint-btn" onClick={addNewAddress ? handleAddAddress : handleUpdate} isLoading={isLoading} disabled={editingAddress && (isAddressEmpty(newAddress) || Object.values(errors).filter(i => i).length > 0)}>
                                    {t('saveAddress')}
                                </Button>
                            </>}
                    </div>
                </section>
            </>;
  }
  return <>
            <button className="overlay" onClick={onClose}></button>
            <section className="drawer address-book">
                <div className="sheetHeader">
                    <div className="back-icon">
                        <button className="back-btn" onClick={onClose}>
                            <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                        </button>

                        <h4>{t('address_book')}</h4>
                    </div>

                    <button className="closeBtn" onClick={onClose}>
                        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="index.jsx" />
                    </button>
                </div>
                <div className="location-wrap">
                    <div className="saved-locations">
                        <AddressListStatus isLoading={isLoading} addresses={addresses} renderAddressItem={renderAddressItem} t={t} data-sentry-element="AddressListStatus" data-sentry-source-file="index.jsx" />
                    </div>
                </div>
                <button className="logoutProfile" onClick={handleAddAddressClick}>
                    {t('add_new_address')}
                </button>
            </section>
        </>;
};
export default AddressBooks;
AddressBooks.propTypes = {
  onClose: PropTypes.func
};
const AddressListStatus = ({
  isLoading,
  addresses,
  renderAddressItem,
  t
}) => {
  if (isLoading) {
    return <SkeletonLoader className="p3" layout={takeAwayDetails} />;
  }
  if (addresses.length === 0) {
    return <div>{t('no_saved_address_found')}</div>;
  }
  return addresses.map(renderAddressItem);
};
AddressListStatus.propTypes = {
  isLoading: PropTypes.bool,
  addresses: PropTypes.array,
  renderAddressItem: PropTypes.func,
  t: PropTypes.func
};