import { localStorageKeys, modalTypeKey, PRODUCT_MODE } from '@/configs/constants';
import { useAuth } from '@/hooks/useAuth';
import useLocalStorage from '@/hooks/useLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import { SEARCH_PRODUCTS } from '@/lib/graphql/query/home';
import { useQuery } from '@apollo/client';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modalTypes } from '../ModalWrapper';
import Search from '../icons/Search';
import SkeletonLoader from '../loader/SkeltonLoader/SkeltonLoader';
import { placeSearch } from '../loader/SkeltonLoader/loaderConfigs';
const SearchBar = () => {
  const t = useTranslations('search');
  const {
    addMultipleParams
  } = useUrlParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const orderData = useSelector(state => state.order)?.order;
  const accountData = useSelector(state => state.account)?.account;
  const {
    currentStore
  } = useSelector(store => store.store);
  const selectedOrderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
  const {
    getLocaleContent
  } = useAuth();
  useEffect(() => {
    if (searchTerm.length >= 3) {
      setDebouncedTerm(searchTerm);
    } else {
      setDebouncedTerm('');
    }
  }, [searchTerm]);
  const {
    loading,
    data
  } = useQuery(SEARCH_PRODUCTS, {
    skip: debouncedTerm.length < 3,
    variables: {
      keyword: debouncedTerm,
      companyId: orderData?.companyId || Number(accountData?.selectedCountry?.defaultCompanyId),
      storeId: currentStore?.id,
      orderTypes: [selectedOrderType]
    },
    fetchPolicy: 'no-cache'
  });
  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };
  const handleProductSelect = product => {
    setSearchTerm('');
    setDebouncedTerm('');
    addMultipleParams({
      [modalTypeKey]: modalTypes.productDetails,
      id: product?.id,
      mode: PRODUCT_MODE.CREATE
    });
  };
  return <div className="searchBar_wrapper" data-sentry-component="SearchBar" data-sentry-source-file="Searchbar.jsx">
            <div className="searchBar">
                <input type="search" className="form-control" placeholder={t('search_placeholder')} value={searchTerm} onChange={handleSearchChange} />
                <button className="iconBtn">
                    <Search data-sentry-element="Search" data-sentry-source-file="Searchbar.jsx" />
                </button>
            </div>

            {searchTerm.length >= 3 && loading && <SkeletonLoader layout={placeSearch} className="no-place-found flex-start p2" />}

            {!loading && debouncedTerm.length >= 3 && <div className="autocomplete-dropdown">
                    {data?.searchProductQuery?.length > 0 ? data.searchProductQuery.map(product => <button key={product.id} className="autocomplete-item" onClick={() => handleProductSelect(product)}>
                                <h3>{getLocaleContent(product.name)}</h3>
                                <p>{getLocaleContent(product.description)}</p>
                            </button>) : <div className="no-place-found">
                            <h4>{t('noPlacesAvailableTitle')}</h4> <p>{t('noPlacesAvailableDescription')}</p>
                        </div>}
                </div>}
        </div>;
};
export default SearchBar;