import { modalTypes } from '@/components/ModalWrapper';
import SkeletonLoader from '@/components/loader/SkeltonLoader/SkeltonLoader';
import { placeSearch } from '@/components/loader/SkeltonLoader/loaderConfigs';
import { addressSaveTypes, localStorageKeys, ORDER_TYPES } from '@/configs/constants';
import useCartFoodLines from '@/hooks/useCartData';
import useLocalStorage from '@/hooks/useLocalStorage';
import { updateCurrentOrder } from '@/redux/slices/order';
import { isGlobalOrder } from '@/utils/validations';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState } from 'react';
import OneSignal from 'react-onesignal';
import { useDispatch } from 'react-redux';
import AddressForm from '../AddressForm';
import CountrySelect from '../CountrySelect';
import GetDeviceLocation from '../GetDeviceLocation';
import LocationMap from '../LocationMap';
import PlaceSearch from '../PlaceSearch';
import RecentSearches from '../RecentSearches';
import SavedAddresses from '../SavedAddresses';
import { FLOW_STEPS, FLOW_TYPES } from '../config';
import useLocationForm from '../hooks/useLocationForm';

const Delivery = ({
    formData,
    handleChange,
    errors,
    config,
    onClose,
    handleSearchComplete,
    handleSuggestionSelect,
    goToNextStep,
    handleBackClick,
    mode,
    validateField,
    handlePlaceSelect,
    updateCoordinates,
    handleAddNewAddress,
    currentStep,
    shouldSearch,
    setShouldSearch,
    getCountry = () => {},
}) => {
    const [isPlaceSearhing, setIsPlaceSearhing] = useState(false);
    const router = useRouter();
    const t = useTranslations('LocationSelector');
    const dispatch = useDispatch();
    const { cart: cartData } = useCartFoodLines();
    const { actions } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
    const { getNearestStoreData, validateLocationChange } = useLocationForm();

    const { type = ORDER_TYPES.DELIVERY, modalType, cartAddressUpdate } = router.query;
    const { country, selectedPlace, suggestions = [], isDataPopulated } = formData;

    const handleMarkerChange = async (coordinates) => {
        updateCoordinates(coordinates);
    };

    const handleRecentSearchSelect = ({ address, coordinates }) => {
        updateCoordinates(coordinates);
        if (address.country) {
            handleCountrySelect(address.country);
        }
        goToNextStep(FLOW_STEPS.CONFIRM_LOCATION);
    };

    const handleSavedAddressSelect = async (address) => {
        const nearestStoreData = await getNearestStoreData({
            lat: address?.address?.geoData?.coordinates?.lat,
            lng: address?.address?.geoData?.coordinates?.lng,
            type,
        });

        const formattedData = {
            type: type || ORDER_TYPES.DELIVERY,
            ...address,
            id: address.id,
            isPrimary: false,
            companyId: nearestStoreData?.data?.companyId,
            storeId: nearestStoreData?.data?.id,
            countryCode: nearestStoreData?.data?.companyId,
            outletCode: nearestStoreData?.data?.outletCode,
            companyData: {
                taxConfig: nearestStoreData?.data?.companyConfig?.taxConfig,
                currency: nearestStoreData?.data?.companyConfig?.currency,
                country: nearestStoreData?.data?.companyConfig?.country,
                deliveryConfig: nearestStoreData?.data?.storeConfig?.deliveryConfig,
                biteCoinConfig: nearestStoreData?.data?.companyConfig?.biteCoinConfig,
            },
        };
        OneSignal.User.addTags({
            LastCompanyId: formattedData.companyId,
            LastStoreId: formattedData.storeId,
        });
        actions.updateNestedValue(localStorageKeys.SELECTED_ADDRESS, formattedData);
        dispatch(updateCurrentOrder(formattedData));
        goToNextStep();
        onClose();
    };

    const handleDeviceLocation = (locationData) => {
        goToNextStep(FLOW_STEPS.CONFIRM_LOCATION);
        updateCoordinates(locationData.data);
    };

    const shouldShowAddNewButton = () => {
        if (
            cartData.id &&
            cartAddressUpdate === 'true' &&
            currentStep === FLOW_STEPS.SELECT_ADDRESS &&
            !isGlobalOrder(type)
        ) {
            return true;
        }
        return (
            config.addNewButton?.visible &&
            !selectedPlace &&
            formData.addressSaveType !== addressSaveTypes.NEW_ADDRESS &&
            [FLOW_STEPS.SELECT_LOCATION, FLOW_STEPS.SELECT_ADDRESS].includes(currentStep)
        );
    };

    const handleCountrySelect = (value) => {
        handleChange('selectedPlace', null);
        handleChange('country', value.meta);
        OneSignal.User.addTags({ LastCountry: value.meta?.isoCode || value.isoCode });
    };

    const shouldShowRecentSearches = () => {
        return (
            modalType === modalTypes.locationSelector &&
            [FLOW_TYPES.SEARCH_LOCATION, FLOW_TYPES.GLOBAL_ORDER].includes(mode) &&
            [FLOW_STEPS.SELECT_LOCATION].includes(currentStep) &&
            !shouldSearch
        );
    };

    const shouldShowSavedAddresses = () => {
        return (
            config.savedLocations?.visible &&
            [FLOW_STEPS.SELECT_LOCATION, FLOW_STEPS.SELECT_ADDRESS].includes(currentStep)
        );
    };

    const shouldShowPlaceSearch = () => {
        if (!config.placeSearch?.visible) return false;
        if (modalType === modalTypes.locationSelector && mode === FLOW_TYPES.SEARCH_LOCATION) {
            return true;
        }
        if (mode === FLOW_TYPES.SELECT_ADDRESS && cartAddressUpdate === 'true') {
            return [
                FLOW_STEPS.SEARCH_LOCATION,
                FLOW_STEPS.SELECT_ADDRESS,
                FLOW_STEPS.CONFIRM_LOCATION,
                FLOW_STEPS.SAVE_LOCATION,
            ].includes(currentStep);
        }
        return true;
    };

    const shouldShowAddressForm = () => {
        if (!config.addressForm?.visible) {
            return false;
        }
        return mode === FLOW_TYPES.SELECT_ADDRESS || formData.addressSaveType === addressSaveTypes.NEW_ADDRESS;
    };

    const shouldShowPlaceSearchNoResults = () => {
        if (isPlaceSearhing) return false;
        return !config.placeSearch?.isInternalDropDown && shouldSearch && !isDataPopulated && !suggestions?.length;
    };

    const getClassName = () => {
        if ([FLOW_TYPES.GLOBAL_ORDER].includes(mode)) {
            return 'rowGroup';
        }
        if (FLOW_TYPES.SELECT_ADDRESS === mode && type.includes('GLOBAL')) {
            return 'rowGroup';
        }
        return '';
    };

    return (
        <>
            <div className="location-block">
                <div className={getClassName()}>
                    {config.countrySelect?.visible && (
                        <div className="formGroup">
                            <label htmlFor="dob">{t('selectCountryLabel')}</label>
                            <CountrySelect value={country} onChange={handleCountrySelect} error={errors.country} />
                        </div>
                    )}
                    {shouldShowPlaceSearch() && (
                        <div className="formGroup">
                            {[FLOW_TYPES.GLOBAL_ORDER, FLOW_TYPES.SELECT_ADDRESS].includes(mode) && (
                                <label htmlFor="dob">{t('selectLocationLabel')}</label>
                            )}
                            <PlaceSearch
                                isInternalDropDown={config.placeSearch.isInternalDropDown}
                                country={getCountry()}
                                value={selectedPlace?.address?.geoData}
                                onChange={handlePlaceSelect}
                                error={errors.place}
                                onSearchComplete={handleSearchComplete}
                                isDataPopulated={isDataPopulated}
                                handleChange={handleChange}
                                shouldSearch={shouldSearch}
                                setShouldSearch={setShouldSearch}
                                goToNextStep={goToNextStep}
                                setIsPlaceSearhing={setIsPlaceSearhing}
                            />
                        </div>
                    )}
                </div>
                {config.deviceLocation?.visible &&
                    formData.addressSaveType !== addressSaveTypes.NEW_ADDRESS &&
                    !selectedPlace?.coordinates && <GetDeviceLocation onSuccess={handleDeviceLocation} />}
                {shouldShowAddNewButton() && (
                    <button className="new-address-btn" onClick={handleAddNewAddress}>
                        {t('addNewAddress')}
                    </button>
                )}
            </div>
            {!config.placeSearch?.isInternalDropDown && !isDataPopulated && shouldSearch && suggestions?.length > 0 && (
                <div className="recent-search common-place-search-results">
                    <h4>{t('searchResult')}</h4>
                    <div className="recent-search-list">
                        {suggestions.map((suggestion) => (
                            <button key={suggestion?.text?.text} onClick={() => handleSuggestionSelect(suggestion)}>
                                {suggestion?.text?.text}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {shouldShowPlaceSearchNoResults() && (
                <div className="no-place-found p2">
                    <p>{t('no_results')}</p>
                </div>
            )}
            {!config.placeSearch?.isInternalDropDown && isPlaceSearhing && <SkeletonLoader layout={placeSearch} />}
            {config.map?.visible && selectedPlace?.address?.geoData?.coordinates && !shouldSearch && (
                <div className="location-map">
                    <LocationMap
                        coordinates={selectedPlace?.address?.geoData?.coordinates}
                        onLocationChange={handleMarkerChange}
                        error={errors.coordinates}
                        preventZoom={mode === FLOW_TYPES.GLOBAL_ORDER}
                        preventMarkerMovement={mode === FLOW_TYPES.GLOBAL_ORDER}
                    />

                    <div className="delivery-address">
                        <h6>
                            {t('deliverTo')} -<span>{selectedPlace?.address?.geoData?.formattedAddress}</span>
                        </h6>
                        <button className="btn-change" onClick={handleBackClick}>
                            {t('change')}
                        </button>
                    </div>

                    {shouldShowAddressForm() && (
                        <AddressForm
                            currentAddress={formData}
                            handleFieldChange={handleChange}
                            errorsData={errors}
                            validateField={validateField}
                        />
                    )}
                </div>
            )}
            {shouldShowSavedAddresses() && (
                <SavedAddresses
                    onSelect={handleSavedAddressSelect}
                    type={type}
                    onClose={onClose}
                    validateLocationChange={validateLocationChange}
                />
            )}
            {shouldShowRecentSearches() && <RecentSearches onSelect={(place) => handleRecentSearchSelect(place)} />}
        </>
    );
};

export default Delivery;

Delivery.propTypes = {
    mode: PropTypes.string,
    formData: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSearchComplete: PropTypes.func.isRequired,
    handleSuggestionSelect: PropTypes.func.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    validateField: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    handlePlaceSelect: PropTypes.func.isRequired,
    handleAddNewAddress: PropTypes.func.isRequired,
    updateCoordinates: PropTypes.func.isRequired,
    currentStep: PropTypes.any,
    shouldSearch: PropTypes.bool,
    setShouldSearch: PropTypes.func.isRequired,
    getCountry: PropTypes.func.isRequired,
};
