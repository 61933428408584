import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    order: {},
    activeOrderStatus: [],
};

const orderSlice = createSlice({
    name: 'Order',
    initialState,
    reducers: {
        setActiveOrderStatus(state, action) {
            state.activeOrderStatus = action.payload;
        },
        updateCurrentOrder(state, action) {
            return {
                order: {
                    ...state.order,
                    ...action.payload,
                },
            };
        },
        setCurrentOrder(state, action) {
            state.order = action.payload;
        },
        removeScheduledTime(state) {
            if (state.order?.scheduledTime) {
                delete state.order.scheduledTime;
            }
        },
    },
});

export const { updateCurrentOrder, setCurrentOrder, removeScheduledTime, setActiveOrderStatus } = orderSlice.actions;
export default orderSlice.reducer;
