import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import RemoteImg from '../Image/RemoteImg';
const settings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  dots: false,
  variableWidth: true,
  autoplay: true,
  cssEase: 'linear',
  // nav: true,
  rows: 1,
  responsive: [{
    breakpoint: 769,
    settings: {
      centerMode: false,
      variableWidth: false
    }
  }]
};
function FullWidthSlider() {
  const router = useRouter();
  const {
    home
  } = useSelector(store => store.common);
  if (!home?.BANNER?.length) {
    return null;
  }
  function handleBannerClick(bannerData) {
    const [type, id] = bannerData.link.split('/');
    let url = '';
    if (type === 'PRODUCT' && id) {
      url = `/categories?modalType=productDetails&id=${id}&mode=create&companyId=${bannerData.companyId}`;
    } else if (type === 'CATEGORY' && id) {
      url = `/categories#${id}`;
    }
    if (url) {
      router.push(url);
    }
  }
  const items = home?.BANNER?.map(item => {
    return <button className="item" key={item.web?.split('=').pop()} onClick={() => handleBannerClick(item)}>
                <RemoteImg src={item.web} alt={item.description} />
            </button>;
  });
  return <div className={`offersContainer ${home?.BANNER?.length === 1 ? 'single-item' : ''}`} data-sentry-component="FullWidthSlider" data-sentry-source-file="FullwidthSlider.jsx">
            <div className="slider-container">
                <Slider {...{
        ...settings,
        infinite: home?.BANNER?.length > 1,
        dots: false,
        arrows: home?.BANNER?.length > 1
      }} data-sentry-element="Slider" data-sentry-source-file="FullwidthSlider.jsx">
                    {items}
                </Slider>
            </div>
        </div>;
}
FullWidthSlider.propTypes = {
  data: PropTypes.array
};
export default FullWidthSlider;