import ErrorBoundary from '@/components/ErrorBoundary';
import AuthGuard from '@/components/Layout/AuthGuard';
import MainLayout from '@/components/Layout/MainLayout';
import ModalWrapper from '@/components/ModalWrapper';
import { localStorageKeys, modalTypeKey, ORDER_STATUS } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import useRouteChange from '@/hooks/useRouteChange';
import useUrlParams from '@/hooks/useUrlParams';
import client from '@/lib/graphql/apolloClient';
import enMessages from '@/lib/messages/langs/en.json';
import { wrapper } from '@/redux/store';
import '@/styles/_main.scss';
import { ApolloProvider } from '@apollo/client';
import { NextIntlClientProvider } from 'next-intl';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../components/PhoneNumberSelect/phoneNumberSelect.scss';
const Loader = dynamic(() => import('@/components/loader'), {
  ssr: false
});
export default function App({
  Component,
  ...rest
}) {
  const {
    props,
    store
  } = wrapper.useWrappedStore(rest);
  const router = useRouter();
  const [mounted, setMounted] = useState(false);
  const {
    addMultipleParams
  } = useUrlParams();
  const {
    actions
  } = useLocalStorage('orderListenStatus');
  const {
    actions: authActions
  } = useLocalStorage(localStorageKeys.AUTH_DATA);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userId = authActions.getNestedValue('userId');
      setUserId(userId);
    }
  }, [router.pathname]);
  useRouteChange(() => {
    if (router.pathname === '/order') return;
    const paymentStatus = actions.getNestedValue('status');
    if (paymentStatus === ORDER_STATUS.COMPLETED) {
      actions.updateNestedValue('status', '');
      addMultipleParams({
        [modalTypeKey]: 'selectOrderType',
        clearAddress: true
      });
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    console.warn('env', process.env.NEXT_PUBLIC_ENV);
  }, []);
  useRouteChange(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
  const initOneSignal = useCallback(async () => {
    if (typeof window !== 'undefined') {
      await OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
        notifyButton: {
          enable: true
        },
        allowLocalhostAsSecureOrigin: true
      });
    }
  }, []);
  const loginOneSignal = useCallback(async () => {
    if (typeof window !== 'undefined' && userId) {
      await OneSignal.login(userId);
      OneSignal.User.addTags({
        UserType: 'CUSTOMER'
      });
      if (!OneSignal.Notifications.permission) {
        OneSignal.Notifications.requestPermission();
      }
    }
  }, [userId]);
  useEffect(() => {
    setMounted(true);
    initOneSignal();
  }, []);
  useEffect(() => {
    if (userId) {
      loginOneSignal();
    }
  }, [userId]);
  const messages = props.pageProps?.messages || enMessages || {};
  const withProviders = children => <NextIntlClientProvider messages={messages} locale={router.locale || router.defaultLocale} now={new Date()} onError={err => {
    console.error('Intl Error:', {
      ...err
    });
  }} data-sentry-element="NextIntlClientProvider" data-sentry-component="withProviders" data-sentry-source-file="_app.jsx">
            <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.jsx">
                <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.jsx">{children}</Provider>
            </ApolloProvider>
        </NextIntlClientProvider>;
  const pageTitle = 'Chicking';
  const is404 = router.pathname === '/404';
  const renderContent = () => {
    if (!mounted) {
      return <div className="min-h-screen flex items-center justify-center">
                    <Loader />
                </div>;
    }
    if (Component.requireAuth) {
      return <AuthGuard>
                    <PageWrapper>
                        <Component {...props.pageProps} />
                    </PageWrapper>
                </AuthGuard>;
    }
    if (is404) {
      return <Component {...props.pageProps} />;
    }
    return <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-component="renderContent" data-sentry-source-file="_app.jsx">
                <PageWrapper data-sentry-element="PageWrapper" data-sentry-source-file="_app.jsx">
                    <Component {...props.pageProps} data-sentry-element="Component" data-sentry-source-file="_app.jsx" />
                </PageWrapper>
            </ErrorBoundary>;
  };
  return withProviders(<>
            <Head>
                <title>{pageTitle}</title>
            </Head>
            {renderContent()}
        </>);
}
App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
};
function PageWrapper({
  children
}) {
  return <>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} icon={false} closeOnClick pauseOnHover data-sentry-element="ToastContainer" data-sentry-source-file="_app.jsx" />
            <Loader data-sentry-element="Loader" data-sentry-source-file="_app.jsx" />
            <ModalWrapper data-sentry-element="ModalWrapper" data-sentry-source-file="_app.jsx" />
            <MainLayout data-sentry-element="MainLayout" data-sentry-source-file="_app.jsx">{children}</MainLayout>
        </>;
}
PageWrapper.propTypes = {
  children: PropTypes.node.isRequired
};