import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { PromoStatus } from '@/configs/constants';
import { firestore } from '@/configs/firebase.config';
import { callCloudFunction } from '@/lib/firebase/functions';
import { debugLog, generateHomeData, transformTimestamps } from '@/utils';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import functions from '@/utils/constants/fb.functions';
/**
 * Get the home data for a given country and location.
 * If the location is not provided, the default company for the given country is used.
 * @param {string} country - The country to get the data for.
 * @param {string} [location] - The location to get the data for.
 * @returns {Promise<QueryDocumentSnapshot[]>} - The snapshot of the documents from the home subcollection.
 * @throws {Error} - If there is an error getting the data.
 */
export const getHomeData = async (country, orderDetails, orderType = 'DELIVERY') => {
    if (!country) return;
    try {
        if (!country) return;
        let ref;
        let companyId;
        let companyRef = collection(firestore, collections.companies);
        if (!orderDetails?.companyId) {
            const countryRef = doc(firestore, collections.countries, country);
            const docSnap = await getDoc(countryRef);
            if (docSnap.exists()) {
                companyId = String(docSnap.data()?.defaultCompanyId);
                ref = collection(companyRef, String(docSnap.data()?.defaultCompanyId), subCollections.home);
            }
        } else {
            ref = collection(
                firestore,
                collections.companies,
                String(orderDetails.companyId),
                subCollections.stores,
                String(orderDetails.storeId),
                subCollections.home
            );

            companyId = String(orderDetails?.companyId);
        }
        const homeQuery = query(
            ref,
            where('data.status', '==', PromoStatus.ACTIVE),
            orderBy('type'),
            orderBy('data.sortOrder')
        );
        const querySnapshot = await getDocs(homeQuery);
        const companyDetails = await getDoc(doc(firestore, collections.companies, companyId));

        const timestampUpdatedHomeData = transformTimestamps(
            generateHomeData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })),
                orderType
            )
        );

        let testimonials = [];
        if (!timestampUpdatedHomeData?.TESTIMONIALS?.length) {
            const testimonialsRef = collection(
                firestore,
                `/${collections.companies}/${companyId}/${subCollections.testimonials}`
            );
            const testimonialsSnap = await getDocs(testimonialsRef);

            if (!testimonialsSnap.empty) {
                testimonials = testimonialsSnap.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
            }
        }

        const timestampUpdatedCompanyDetails = transformTimestamps(companyDetails.data());
        return {
            home: !timestampUpdatedHomeData?.TESTIMONIALS
                ? { ...timestampUpdatedHomeData, TESTIMONIALS: testimonials }
                : timestampUpdatedHomeData,
            companyDetails: timestampUpdatedCompanyDetails,
        };
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
};

/**
 * Get real-time updates for the home data for a given country and location.
 * If the location is not provided, the default company for the given country is used.
 * @param {string} country - The country to get the data for.
 * @param {function} callback - The callback function to handle the real-time data.
 * @param {string} [location] - The location to get the data for.
 * @returns {function} - The unsubscribe function to stop listening to updates.
 * @throws {Error} - If there is an error getting the data.
 */
export const getHomeDataRealtime = async (country, callback, orderDetails, currentStore, orderType = 'DELIVERY') => {
    try {
        let ref;
        let companyId;
        let companyRef = collection(firestore, collections.companies);
        let listener;

        debugLog({
            message: 'fetchHomeData getHomeDataRealtime Params',
            params: {
                country,
                orderDetails,
                orderType,
                currentStore,
            },
        });
        if (!currentStore) {
            const countryRef = doc(firestore, collections.countries, country?.id);
            const docSnap = await getDoc(countryRef);
            if (docSnap.exists()) {
                companyId = String(docSnap.data()?.defaultCompanyId);
                ref = collection(companyRef, String(docSnap.data()?.defaultCompanyId), subCollections.home);
                listener = await setupListener(ref, companyId, callback, orderType, currentStore, country);
            }
        } else {
            ref = collection(
                firestore,
                collections.companies,
                String(currentStore.companyId),
                subCollections.stores,
                String(currentStore.id),
                subCollections.home
            );
            companyId = String(currentStore?.companyId);
            listener = await setupListener(ref, companyId, callback, orderType, currentStore, country);
            debugLog({
                message: 'fetchHomeData getHomeDataRealtime with storeid',
                params: {
                    currentStoreId: currentStore.id,
                    companyId,
                },
            });
        }
        debugLog({
            message: 'fetchHomeData getHomeDataRealtime',
            params: {
                listener,
                country,
                companyId,
                callback,
                orderDetails,
                orderType,
            },
        });
        return listener;
    } catch (e) {
        console.error('Error setting up real-time listener:', e);
        throw e;
    }
};

const setupListener = async (ref, companyId, callback, orderType, currentStore, country) => {
    let homeQuery = query(
        ref,
        where('data.status', '==', PromoStatus.ACTIVE),
        orderBy('type'),
        orderBy('data.sortOrder')
    );
    if (!currentStore) {
        homeQuery = query(homeQuery, where('data.isDefault', '==', true));
    }
    const companyDetails = await getDoc(doc(firestore, collections.companies, companyId));
    const timestampUpdatedCompanyDetails = transformTimestamps(companyDetails.data());
    const testimonials = await fetchTestimonials(companyId);
    const unsubscribe = onSnapshot(homeQuery, (querySnapshot) => {
        debugLog({
            message: 'fetchHomeData querySnapshot.docs',
            params: {
                data: querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })),
            },
        });
        const timestampUpdatedHomeData = transformTimestamps(
            generateHomeData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })),
                orderType,
                country
            )
        );

        debugLog({
            message: 'fetchHomeData setupListener',
            params: {
                companyId,
                currentStore,
                orderType,
                timestampUpdatedCompanyDetails,
                testimonials,
            },
        });

        callback({
            home: !timestampUpdatedHomeData?.TESTIMONIALS
                ? { ...timestampUpdatedHomeData, TESTIMONIALS: testimonials }
                : timestampUpdatedHomeData,
            companyDetails: timestampUpdatedCompanyDetails,
        });
    });

    return unsubscribe;
};

const fetchTestimonials = async (companyId) => {
    const testimonialsRef = collection(
        firestore,
        `/${collections.companies}/${companyId}/${subCollections.testimonials}`
    );
    const testimonialsSnap = await getDocs(testimonialsRef);

    if (!testimonialsSnap.empty) {
        return testimonialsSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
    }
    return [];
};

/**
 * Gets the products of a given store.
 *
 * @param {string} companyId The id of the company.
 * @param {string} storeId The id of the store.
 * @param {string} collectionName The name of the collection.
 * @param {string} subCollectionName The name of the subcollection.
 * @param {string} innerCollectionName The name of the inner collection.
 * @returns {Promise<Array<{id: string, [key: string]: any}>>} An array of objects with the id and data of each product.
 * @throws {Error} If there is an error getting the products.
 */
export const getStoreProducts = async (companyId, storeId, collectionName, subCollectionName, innerCollectionName) => {
    try {
        const productRef = collection(
            doc(firestore, `${collectionName}/${companyId}/${subCollectionName}/${storeId}`),
            innerCollectionName
        );
        const querySnapshot = await getDocs(productRef);
        const response = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return transformTimestamps(response);
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
};

export const getNearByStores = async (request) => {
    try {
        const response = await callCloudFunction(functions.nearByStores, request);
        return transformTimestamps(response);
    } catch (error) {
        console.error('error in getNearByStores: ', error);
        throw error;
    }
};

export const getNearestStore = async (request) => {
    try {
        const response = await callCloudFunction(functions.getNearestStore, request);
        return transformTimestamps(response);
    } catch (error) {
        console.error('error in getNearestStore: ', error);
        throw error;
    }
};

export const getCountries = async () => {
    try {
        const countriesRef = collection(firestore, collections.countries);
        const sortedQuery = query(countriesRef, where('hidden', '==', false), orderBy('sOrder', 'asc'));
        const querySnapshot = await getDocs(sortedQuery);

        // Map the documents into an array
        const countries = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        return transformTimestamps(countries);
    } catch (e) {
        console.error('Error listing countries:', e);
        throw e;
    }
};
export const getMasterCountries = async () => {
    try {
        const countriesRef = collection(firestore, collections.masterCountries);
        const sortedQuery = query(countriesRef, orderBy('name.en', 'asc'));
        const querySnapshot = await getDocs(sortedQuery);

        const masterCountries = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return masterCountries;
    } catch (e) {
        console.error('Error listing countries:', e);
        throw e;
    }
};

// firebase query to read all data from home collection if the
