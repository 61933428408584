const collections = {
    customers: 'customers',
    companies: 'companies',
    categories: 'categories',
    products: 'products',
    countries: 'countries',
    carts: 'carts',
    masterCountries: 'masterCountries',
};

const subCollections = {
    categories: 'categories',
    banners: 'banners',
    products: 'products',
    stores: 'stores',
    feedbacks: 'feedbacks',
    modifierGroups: 'modifierGroups',
    modifiers: 'modifiers',
    promocodes: 'promocodes',
    orders: 'orders',
    addresses: 'addresses',
    home: 'home',
    orderHistory: 'orderHistory',
    carts: 'carts',
    foodLines: 'foodLines',
    favourites: 'favourites',
    testimonials: 'testimonials',
    promocodeHistory: 'promocodeHistory',
};

export { collections, subCollections };
