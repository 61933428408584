import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { onSnapshot, doc } from 'firebase/firestore';
import { collections } from '@/utils/constants/fb.collections';
import { firestore } from '@/configs/firebase.config';

const useCustomer = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const authData = useSelector((state) => state.auth)?.auth;

    useEffect(() => {
        // Return early if authData or uid is unavailable
        if (!authData?.uid) {
            setLoading(false);
            return;
        }

        // Reference the Firestore document with the specific UID
        const customerRef = doc(firestore, collections.customers, authData.uid);

        const unsubscribe = onSnapshot(
            customerRef,
            (snapshot) => {
                if (snapshot.exists()) {
                    setData({ id: snapshot.id, ...snapshot.data() });
                } else {
                    setData(null);
                }
                setLoading(false);
            },
            (err) => {
                console.error('Error fetching Firestore data:', err);
                setError(err);
                setLoading(false);
            }
        );

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [authData]); // Only run when authData.uid changes

    return { data, loading, error };
};

export default useCustomer;
