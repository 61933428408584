import { useEffect, useState } from 'react';
import { listenToCartFoodLines } from '@/service/cart';
import { useSelector } from 'react-redux';
const useCartFoodLines = () => {
  const authData = useSelector(store => store.auth).auth;
  const [foodLines, setFoodLines] = useState([]);
  const [cart, setCart] = useState({});
  const [loading, setLoading] = useState(true);
  const handleRefresh = async () => {
    setCart({});
    setFoodLines([]);
  };
  useEffect(() => {
    // Return early if authData.uid is undefined or missing
    if (!authData?.uid) {
      setLoading(false);
      return;
    }

    // Declare unsubscribe inside the effect
    let unsubscribe;
    try {
      // Subscribe to Firestore updates
      unsubscribe = listenToCartFoodLines(authData.uid, data => {
        if (data.success) {
          setFoodLines(data?.foodLines || []);
          setCart(data?.cart || null);
        } else {
          setFoodLines([]);
          setCart({});
        }
        setLoading(false);
      });
    } catch (error) {
      console.error('Error setting up Firestore listener:', error);
      setLoading(false);
    }

    // Cleanup function
    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [authData?.uid]);
  return {
    foodLines,
    cart,
    loading,
    handleRefresh
  };
};
export default useCartFoodLines;