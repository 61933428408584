import React from 'react';
import PropTypes from 'prop-types';
import { assetPath } from '@/lib/helpers/common';
function StaticImg({
  src,
  alt = '',
  ...props
}) {
  return <img src={assetPath(src)} alt={alt} {...props} data-sentry-component="StaticImg" data-sentry-source-file="StaticImg.jsx" />;
}
StaticImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};
export default StaticImg;