import { modalTypes } from '@/components/ModalWrapper';
import { localStorageKeys, modalTypeKey } from '@/configs/constants';
import { updateCurrentAccount } from '@/redux/slices/account';
import { debugLog, isFalsy } from '@/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeviceLocation from './useDeviceLocation';
import useLocalStorage from './useLocalStorage';
import useReverseGeocoding from './useReverseGeocoding';
import useUrlParams from './useUrlParams';
export const useLocationOnboarding = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    getLocation
  } = useDeviceLocation();
  const blockOtherRoutesForMobile = process.env.NEXT_PUBLIC_BLOCK_FOR_MOBILE;
  const {
    addMultipleParams,
    removeAllParams
  } = useUrlParams();
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const {
    fetchAddresses
  } = useReverseGeocoding();
  const accountData = useSelector(store => store.account)?.account;
  const commonData = useSelector(store => store.common);
  const {
    modalType
  } = router.query;
  const {
    deviceLocation,
    selectedCountry
  } = accountData || {};
  const openModalBasedOnState = () => {
    const selectedOrderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
    const currentModalType = router.query[modalTypeKey];
    debugLog({
      message: 'useLocationOnboarding openModalBasedOnState',
      params: {
        accountData,
        deviceLocation,
        selectedCountry,
        selectedOrderType,
        commonData,
        currentModalType
      }
    });
    if (!selectedCountry && deviceLocation?.isLocationAllowed === false) {
      if (currentModalType !== modalTypes.selectCountry) {
        addMultipleParams({
          [modalTypeKey]: modalTypes.selectCountry
        }, {
          shallow: false
        });
      }
    } else if (!selectedOrderType) {
      if (currentModalType !== modalTypes.selectOrderType) {
        addMultipleParams({
          [modalTypeKey]: modalTypes.selectOrderType
        }, {
          shallow: false
        });
      }
    }
  };
  const defaultCountryIsoCode = 'AE';
  const setCurrentCountry = async coordinates => {
    try {
      const latlng = Object.values(coordinates).join(',');
      const addresses = await fetchAddresses([latlng]);
      if (addresses[0]) {
        const {
          address_components
        } = addresses[0];
        const currentCountry = address_components.find(i => i.types.includes('country'));
        let selectedCountryData = commonData.countries.find(i => i.isoCode === currentCountry.short_name);
        const availableCountries = commonData.countries.map(i => i.isoCode);
        if (!availableCountries.includes(currentCountry.short_name)) {
          selectedCountryData = commonData.countries.find(i => i.isoCode === defaultCountryIsoCode);
        }
        if (!isFalsy(selectedCountryData)) {
          actions.updateNestedValue(localStorageKeys.SELECTED_COUNTRY, selectedCountryData);
          dispatch(updateCurrentAccount({
            selectedCountry: selectedCountryData
          }));
        }
        debugLog({
          message: 'useLocationOnboarding setCurrentCountry',
          params: {
            addresses,
            currentCountry,
            selectedCountryData,
            availableCountries
          }
        });
      }
      return addresses[0];
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  const init = async () => {
    try {
      removeAllParams();
      if (!commonData.countries?.length) return;
      const data = await getLocation();
      await setCurrentCountry(data.data);
      openModalBasedOnState();
    } catch (error) {
      openModalBasedOnState();
    }
  };
  useEffect(() => {
    if (blockOtherRoutesForMobile) return;
    if (modalType === 'productDetails' || router.asPath.includes('footerItems')) return;
    const deviceLocation = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION);
    if (isFalsy(deviceLocation)) {
      init();
    } else {
      openModalBasedOnState();
    }
  }, [router.asPath, deviceLocation, commonData.countries?.length]);
};
export default useLocationOnboarding;