import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
const AddressTypeSelector = ({
  currentValue,
  onChange,
  errors,
  locale = 'en'
}) => {
  const [customType, setCustomType] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const t = useTranslations();
  const addressTags = [{
    id: 'home',
    name: 'Home',
    data: {
      en: 'Home',
      ar: 'بيت'
    }
  }, {
    id: 'work',
    name: 'Work',
    data: {
      en: 'Work',
      ar: 'عمل'
    }
  }, {
    id: 'friendsFamily',
    name: 'Friends & Family',
    data: {
      en: 'Friends & Family',
      ar: 'الأصدقاء والعائلة'
    }
  }, {
    id: 'other',
    name: 'Other',
    data: {
      en: 'Other',
      ar: 'آخر'
    }
  }];
  useEffect(() => {
    if (currentValue) {
      const matchingTag = addressTags.find(tag => tag.id === currentValue || Object.values(tag.data).includes(currentValue));
      if (matchingTag) {
        setSelectedType(matchingTag.id);
        setCustomType('');
      } else {
        setSelectedType('other');
        setCustomType(currentValue);
      }
    }
  }, [currentValue]);
  const handleTypeSelect = tagId => {
    setSelectedType(tagId);
    if (tagId !== 'other') {
      const selectedTag = addressTags.find(tag => tag.id === tagId);
      onChange(selectedTag.data[locale] || selectedTag.name);
      setCustomType('');
    } else {
      onChange(tagId);
    }
  };
  const handleCustomTypeChange = e => {
    const value = e.target.value;
    setCustomType(value);
    const error = validateAddressType(value);
    !value ? onChange('other') : onChange(value, error);
  };
  const validateAddressType = value => {
    if (!value || value.trim() === '') {
      return 'addressTypeRequired';
    }
    if (value.length > 50) {
      return 'addressTypeTooLong';
    }
    return '';
  };
  return <div className="save-list-wrap" data-sentry-component="AddressTypeSelector" data-sentry-source-file="AddressTypeSelector.jsx">
            <h4>{t('profile.saveAs')}</h4>
            <div className="save-list">
                {addressTags.map(tag => <button key={tag.id} onClick={() => handleTypeSelect(tag.id)} className={`address-type-btn ${selectedType === tag.id ? 'is-active' : ''}`}>
                        {tag.data[locale] || tag.name}
                    </button>)}
            </div>

            {selectedType === 'other' && <div className="formGroup mt-0-5rem">
                    <label htmlFor="addressType">{t('profile.addressTypeLabel')}</label>
                    <input type="text" name="addressType" id="customType" value={customType} onChange={handleCustomTypeChange} className="formControl" placeholder={t('profile.addresssTypeCustomeTag')} />
                </div>}
            {errors?.addressType && <p className="error-message mt-0-5rem">{t(`ErrorMessages.${errors.addressType}`)}</p>}
        </div>;
};
export default AddressTypeSelector;
AddressTypeSelector.propTypes = {
  currentValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    addressType: PropTypes.string
  }),
  locale: PropTypes.oneOf(['en', 'ar'])
};