import { getGeoCoder } from '@/configs/maps.config';
import { useEffect, useState } from 'react';
const useReverseGeocoding = (latlng = '') => {
  const [addresses, setAddresses] = useState([]);
  const [error, setError] = useState(null);
  const fetchAddresses = async latlngArray => {
    if (!latlngArray || latlngArray.filter(i => i).length === 0) return;
    try {
      const geoCoder = await getGeoCoder();
      const addressPromises = latlngArray.map(async latlng => {
        let parseLtlg = latlng.split(',');
        latlng = {
          lat: parseFloat(parseLtlg[0]),
          lng: parseFloat(parseLtlg[1])
        };
        const params = {
          location: latlng
        };
        const data = await geoCoder.geocode(params);
        if (!data.results?.length > 0) return null;
        const result = data.results[0];
        result.geometry = {
          location: {
            lat: result.geometry?.location?.lat(),
            lng: result.geometry?.location?.lng()
          }
        };
        return result || null;
      });
      const addresses = await Promise.all(addressPromises);
      setAddresses(addresses);
      setError(null);
      return addresses;
    } catch (err) {
      setError(err);
      setAddresses([]);
    }
  };
  useEffect(() => {
    const latlngArray = Array.isArray(latlng) ? latlng : [latlng];
    fetchAddresses(latlngArray);
  }, [latlng]);
  return {
    addresses,
    error,
    fetchAddresses
  };
};
export default useReverseGeocoding;