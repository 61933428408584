import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ChevronRight from '../icons/chevronRight';
import GlobeIcon from '../icons/GlobeIcon';
import StaticImg from '../Image/StaticImg';
import router from 'next/router';
export const PhoneNumberSelect = ({
  onChange = () => {},
  defaultCountry = 'SA',
  direction = 'ltr',
  placeholder,
  isSearchable = true,
  value,
  disabled,
  countryListData = [],
  validatePhoneNumber,
  onCountrySelect,
  shouldShowPhoneCode = true,
  shouldShowArrow = false,
  showError,
  t = () => {},
  classNames = [],
  shouldShowCountryName = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validationState, setValidationState] = useState({
    isValid: false,
    error: ''
  });
  const dropdownRef = useRef(null);
  const filteredCountries = useMemo(() => countryListData.filter(country => country.name.en.toLowerCase().includes(searchTerm.toLowerCase()) || country.phoneCode.includes(searchTerm)), [countryListData, searchTerm]);
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  useEffect(() => {
    const defaultSelection = countryListData.find(country => country.isoCode.toUpperCase() === defaultCountry.toUpperCase());
    if (defaultSelection) {
      setSelectedCountry(defaultSelection);
    }
  }, [defaultCountry, countryListData]);
  useEffect(() => {
    if (value !== undefined) {
      setPhoneNumber(value);
      if (selectedCountry) {
        const validation = validatePhoneNumber(value, selectedCountry);
        setValidationState(validation);
      }
    }
  }, [value, selectedCountry, validatePhoneNumber]);
  const handleCountrySelect = useCallback(country => {
    setSelectedCountry(country);
    setIsOpen(false);
    setSearchTerm('');
    if (onCountrySelect) {
      onCountrySelect(country);
    }
    if (phoneNumber) {
      const validation = validatePhoneNumber(phoneNumber, selectedCountry);
      setValidationState(validation);
      if (validation.isValid) {
        const output = formatOutput(country.phoneCode, phoneNumber);
        onChange(output);
      }
    }
  }, [phoneNumber, onChange, validatePhoneNumber]);
  const handlePhoneChange = useCallback(e => {
    setIsOpen(false);
    const rawValue = e.target.value.replace(/\D/g, '');
    if (selectedCountry && rawValue.length <= selectedCountry.phoneMaxLength) {
      setPhoneNumber(rawValue);
      const validation = validatePhoneNumber(rawValue, selectedCountry);
      setValidationState(validation);
      if (validation.isValid) {
        const output = formatOutput(selectedCountry.phoneCode, rawValue);
        onChange(output);
      }
    }
  }, [selectedCountry, onChange, validatePhoneNumber]);
  const formatOutput = useCallback((code, number) => ({
    code,
    phoneNumber: number,
    formatted: `(${code})${number}`,
    formattedNoSeparator: `${code}${number}`
  }), []);
  return <div className={`country-select ${classNames.join()}`} ref={dropdownRef} dir={direction} data-sentry-component="PhoneNumberSelect" data-sentry-source-file="index.jsx">
            <div className="phone-input" dir={router.locale?.startsWith('ar') ? 'ltr' : ''}>
                {shouldShowCountryName && <button type="button" className="selected-country" onClick={() => !disabled && setIsOpen(!isOpen)} disabled={disabled}>
                        {selectedCountry ? <>
                                <StaticImg src={selectedCountry.flag} alt={selectedCountry.name.en} width={24} height={16} className="flag" />
                                {shouldShowPhoneCode && <span className="phone-code">{selectedCountry.phoneCode}</span>}
                            </> : <GlobeIcon />}
                        {shouldShowArrow && <span className="arrow">
                                <ChevronRight />
                            </span>}
                    </button>}

                <input type="tel" value={phoneNumber} onChange={handlePhoneChange} placeholder={placeholder} disabled={disabled} className={`phone-number-input ${validationState.error ? 'error' : ''}`} dir={router.locale?.startsWith('ar') ? 'ltr' : direction} />
            </div>
            {showError && validationState?.error && <span className="error-message">{t(validationState.error)}</span>}

            {isOpen && <div className="dropdown">
                    {isSearchable && <div className="search-input-wrapper">
                            <input type="text" className="search-input" placeholder="Search country..." value={searchTerm} dir={direction} onChange={e => setSearchTerm(e.target.value)} />
                        </div>}
                    <div className="countries-list">
                        <button type="button" className="country-option" onClick={() => handleCountrySelect('')}>
                            <span className="country-name">Select a country</span>
                        </button>
                        {filteredCountries.map(country => <button key={country.isoCode} type="button" className="country-option" onClick={() => handleCountrySelect(country)}>
                                <StaticImg src={country.flag} alt={country.name.en} width={24} height={16} className="flag" />
                                <span className="country-name">{country.name.en}</span>
                                <span className="country-code">{country.phoneCode}</span>
                            </button>)}
                    </div>
                </div>}
        </div>;
};
PhoneNumberSelect.propTypes = {
  onChange: PropTypes.func,
  t: PropTypes.func,
  validatePhoneNumber: PropTypes.func,
  defaultCountry: PropTypes.string,
  direction: PropTypes.string,
  placeholder: PropTypes.string,
  shouldShowArrow: PropTypes.bool,
  shouldShowCountryName: PropTypes.bool,
  shouldShowPhoneCode: PropTypes.bool,
  showError: PropTypes.bool,
  isSearchable: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
  countryListData: PropTypes.arrayOf(PropTypes.shape({
    isoCode: PropTypes.string.isRequired,
    name: PropTypes.shape({
      en: PropTypes.string.isRequired
    }).isRequired,
    phoneCode: PropTypes.string.isRequired,
    phoneMinLength: PropTypes.number.isRequired,
    phoneMaxLength: PropTypes.number.isRequired,
    phonePattern: PropTypes.string
  }))
};
export default PhoneNumberSelect;