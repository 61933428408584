import React from 'react';
import PropTypes from 'prop-types';
import { getCdnUrl } from '@/lib/helpers/common';
function RemoteImg({
  src,
  alt = '',
  ...props
}) {
  return <img src={getCdnUrl(src)} alt={alt} {...props} data-sentry-component="RemoteImg" data-sentry-source-file="RemoteImg.jsx" />;
}
RemoteImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};
export default RemoteImg;