import { useSelector } from 'react-redux';
const useReduxUtils = () => {
  const accountData = useSelector(store => store.account)?.account;
  const orderData = useSelector(store => store.order)?.order;
  const getSelectedLocation = () => {
    let location = {};
    if (accountData?.deviceLocation?.data) {
      location = accountData?.deviceLocation?.data;
    }
    if (orderData?.address?.geoData?.coordinates) {
      location = orderData?.address?.geoData?.coordinates;
    }
    return location;
  };
  return {
    getSelectedLocation
  };
};
export default useReduxUtils;